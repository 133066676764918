import { Fragment, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { toast } from "react-toastify";

import Flatpickr from "react-flatpickr";
import SimpleBar from "simplebar-react";
import {
  ButtonGroup,
  CardBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import { Popconfirm, Tooltip } from "antd";
import classnames from "classnames";
import dayjs from "dayjs";
import SearchInput from "./SearchInput";
import ButtonTheme from "./ButtonTheme";
import { useDebounce } from "../Hooks/UseDebounce";

import { saveFilterAPI } from "../../helpers/resource_helper";
import { getListDataDefault } from "../../helpers/service_helper";
import Loader from "./Loader";
import { formatDate1 } from "../../helpers/format_helper";

const FilterComponent = ({
  showSearch,
  filterType = "",
  searchPlaceHolder = "",
  numberOfFilters,
  listSaveFilter = [],
  defaultSaveFilter = [],
  handleParamSearch = () => {},
  listFilter = [],
  limit = 10,
  defaultPayload = {},
  filterView = false,
  isRefresh = true,
  defaultDateFilter,
  onCallData = () => {},
  listLoading,
  t,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const debouncedInputValue = useDebounce(searchValue, 1000);
  const [payload, setPayload] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }
  const entries = searchParams.entries();
  const params = paramsToObject(entries);
  const [loading, setLoading] = useState(false);
  const [tabs, setTabs] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [isEditNameFilter, setIsEditNameFilter] = useState(false);
  const [valueInputFilter, setValueInputFilter] = useState("");
  const [filterSave, setFilterSave] = useState({});
  const [firstRun, setFirstRun] = useState(true);
  const [listFilterType, setListFilterType] = useState([]);

  const handleChangeTabsSearch = (newTab, id = "") => {
    let cloneTabs = [...tabs];
    if (id) {
      cloneTabs = cloneTabs.map((item) => {
        if (item.id === id) {
          return newTab;
        }
        return item;
      });
    } else {
      cloneTabs.push(newTab);
      setActiveTab(cloneTabs.length);
    }
    setTabs(cloneTabs);
  };

  const toggleTab = (index, tab) => {
    setActiveTab(index);
    setIsEditNameFilter(false);
    if (tab.type === "default") {
      if (tab.onClick) {
        setPayload({});
      } else {
        setPayload(tab?.filter || {});
      }
    } else {
      setFilterSave(tab);
      setPayload(tab?.filters || {});
    }
  };

  // load all types of filter
  useEffect(() => {
    let newData = [
      {
        id: "created_at",
        type: "date",
        title: "Created date",
      },
      {
        id: "updated_at",
        type: "date",
        title: "Updated date",
      },
    ];
    if (listFilter.length > 0) {
      if (defaultDateFilter) {
        newData = [...listFilter, ...newData];
      } else {
        newData = [...listFilter];
      }
    }
    setListFilterType(newData);
  }, []);

  useEffect(() => {
    const getListSaveFilter = async () => {
      try {
        const res = await saveFilterAPI.get({ id: filterType });
        if (defaultSaveFilter.length > 0) {
          const mapDefaultSaveFilter = defaultSaveFilter.map((item) => ({ ...item, type: "default" }));
          setTabs([...mapDefaultSaveFilter, ...(res?.items || [])]);
        } else {
          setTabs([{ name: "ALL", type: "default" }, ...(res?.items || [])]);
        }
      } catch (e) {
        console.error(String(e));
      }
    };
    !filterView && getListSaveFilter();
  }, []);

  useEffect(() => {
    if (firstRun) {
      setFirstRun(false);
      return;
    }
    const newPayload = {};
    if (payload["query"]) {
      newPayload["query"] = payload["query"];
    }
    Object.keys(payload).forEach((item) => {
      const check = listFilterType.find((subItem) => {
        if (subItem.type !== "date") {
          return subItem.id === item;
        } else {
          return `${subItem.id}_from` === item || `${subItem.id}_to` === item;
        }
      });
      if (check) {
        newPayload[item] = payload[item];
      }
    });
    setSearchParams(newPayload);
    handleParamSearch(newPayload);
  }, [payload]);

  useEffect(() => {
    if (Object.keys(params).length > 0) {
      setPayload(params);
      if (params.query) {
        setSearchValue(params.query);
      }
    } else {
      setPayload(defaultPayload);
      setSearchParams(defaultPayload);
    }
  }, []);

  useEffect(() => {
    if (firstRun) {
      setFirstRun(false);
      return;
    }
    if (showSearch) {
      setPayload((dataPayload) => ({
        ...dataPayload,
        query: debouncedInputValue,
        limit,
      }));
    }
  }, [debouncedInputValue]);

  const handleSaveFilter = async () => {
    try {
      setLoading(true);
      if (filterSave?.id) {
        const res = await saveFilterAPI.put({
          ...filterSave,
          id: `${filterType}/${filterSave.id}`,
          filters: payload,
        });
        handleChangeTabsSearch(res, res.id);
      } else {
        const res = await saveFilterAPI.create({
          name: `Filter ${filterType} ${tabs.length + 1}`,
          type: filterType,
          filters: payload,
        });
        handleChangeTabsSearch(res);
        setPayload({});
        toast.success(t("Save filter success!"));
      }
    } catch (error) {
      toast.error(t(error.toString()));
    } finally {
      setLoading(false);
    }
  };

  const [typeRemoveFilter, setTypeRemoveFilter] = useState("");
  const [loadingEditFilter, setLoadingEditFilter] = useState(false);
  const handleSaveFilterEdit = async (tab) => {
    setLoadingEditFilter(true);
    const res = await saveFilterAPI.put({
      ...tab,
      id: `${filterType}/${tab.id}`,
      name: valueInputFilter,
      filters: tab.filters,
    });
    setLoadingEditFilter(false);
    setTabs((tabs) => {
      const cloneTabs = tabs.map((item) => {
        if (item.id === tab.id) {
          return res;
        }
        return item;
      });
      return cloneTabs;
    });
    setIsEditNameFilter(false);
  };
  return (
    <>
      <CardBody
        className={`${
          filterView ? "my-3" : "border border-dashed border-bottom-0 border-end-0 border-start-0 px-0 py-3"
        }`}
      >
        <div className="hstack gap-3">
          {showSearch && (
            <div className="flex-grow-1">
              <SearchInput
                onChangeValue={(value) => {
                  setSearchValue(value);
                }}
                searchValue={searchValue}
                onDeleteSearchValue={() => setSearchValue("")}
                placeholder={searchPlaceHolder}
              />
            </div>
          )}

          <div className="flex-shrink-0 d-flex">
            {listFilterType.map((item, index) => {
              let startDateKey = item.id + "_from";
              let endDateKey = item.id + "_to";
              return (
                <Fragment key={index}>
                  {index < numberOfFilters &&
                    (item?.type !== "date" ? (
                      <FilterItemDropDown
                        t={t}
                        handleChangeFilterItem={(dataCheck) => {
                          setPayload((dataPayload) => {
                            const newData = { ...dataPayload };
                            if (dataCheck.length > 0) {
                              newData[item.id] = dataCheck.map((i) => i?.id).join();
                            } else {
                              delete newData[item.id];
                            }
                            return newData;
                          });
                        }}
                        type={item?.type}
                        rounded={`rounded-0 ${index === 0 ? "rounded-start" : ""}`}
                        handleNavigate={item?.handleNavigate}
                        dataOption={item?.dataOption || []}
                        title={item?.title}
                        dataCheck={payload[item.id]}
                        remote={item.remote}
                        pathUrlLoad={item.pathUrlLoad}
                        typeRemoveFilter={typeRemoveFilter}
                        id={item.id}
                        onResetTypeRemoveTag={() => {
                          setTypeRemoveFilter("");
                        }}
                        paramsSearch={params}
                      />
                    ) : (
                      <DateDropDown
                        t={t}
                        title={item?.title}
                        rounded={`rounded-0 ${index === 0 ? "rounded-start" : ""}`}
                        handleChangeDate={(data) => {
                          setPayload((dataPayload) => {
                            const newData = { ...dataPayload };
                            if (data && data.length > 0) {
                              newData[startDateKey] = data?.[0]
                                ? item?.formatData === "timestamp"
                                  ? dayjs(new Date(data?.[0])).unix()
                                  : formatDate1(data[0].toISOString())
                                : "";
                              newData[endDateKey] = data?.[1]
                                ? item?.formatData === "timestamp"
                                  ? dayjs(new Date(data?.[1])).unix()
                                  : formatDate1(data[1].toISOString())
                                : "";
                            } else {
                              delete newData[startDateKey];
                              delete newData[endDateKey];
                            }
                            return newData;
                          });
                        }}
                        savedStartDate={
                          payload[startDateKey]
                            ? item?.formatData === "timestamp"
                              ? new Date(dayjs(payload[startDateKey] * 1000))
                              : new Date(payload[startDateKey])
                            : null
                        }
                        savedEndDate={
                          payload[endDateKey]
                            ? item?.formatData === "timestamp"
                              ? new Date(dayjs(payload[endDateKey] * 1000))
                              : new Date(payload[endDateKey])
                            : null
                        }
                        payload={payload}
                      />
                    ))}
                </Fragment>
              );
            })}
            <OtherFilters
              buttonClass={"btn btn-light border-1 rounded-0 rounded-end"}
              listFilterType={listFilterType}
              payload={payload}
              setPayload={setPayload}
              t={t}
            />
          </div>
          {!filterView && (
            <div className="flex-shrink-0">
              <ButtonTheme
                type="button"
                disabled={loading}
                loading={loading}
                loadShowText={true}
                onClick={handleSaveFilter}
                className="btn btn-light border"
              >
                {t("Save filters")}
              </ButtonTheme>
            </div>
          )}
          {isRefresh && (
            <ButtonTheme
              className="btn btn-icon btn-light flex-shrink-0 border"
              onClick={() => {
                onCallData(payload);
              }}
              disabled={listLoading}
            >
              <i className="ri-refresh-line"></i>
            </ButtonTheme>
          )}
        </div>
        {Object.keys(payload).filter((item) => !["query", "limit"].includes(item)).length > 0 && (
          <div className="mt-3">
            {listFilterType.length > 0 &&
              listFilterType.map((item, index) => (
                <RenderTagFilter
                  t={t}
                  key={index}
                  dataFilter={payload[item.id]}
                  payload={payload}
                  item={item}
                  onDelete={(typeTagRender) => {
                    const clonePayload = { ...payload };
                    if (item.type !== "date") {
                      delete clonePayload[item.id];
                    } else {
                      let startDateKey = item.id + "_from";
                      let endDateKey = item.id + "_to";
                      delete clonePayload[startDateKey];
                      delete clonePayload[endDateKey];
                    }
                    if (Object.keys(clonePayload)) setPayload(clonePayload);
                    setTypeRemoveFilter(typeTagRender);
                  }}
                />
              ))}
          </div>
        )}
      </CardBody>
      {!filterView && tabs.length > 0 && (
        <CardBody className="p-0 border border-dashed border-bottom-0 border-end-0 border-start-0 px-0">
          <Nav className="nav-tabs nav-tabs-custom nav-primary" role="tablist">
            {tabs.map((tab, index) => (
              <NavItem key={index} className="d-flex align-items-center">
                <NavLink
                  className={classnames({ active: activeTab === index }, "fw-semibold")}
                  href="#"
                  onClick={() => toggleTab(index, tab)}
                >
                  {tab?.type === "default" ? (
                    typeof tab.name === "string" ? (
                      t(tab.name)
                    ) : (
                      tab.name
                    )
                  ) : (
                    <div className="d-flex align-items-center">
                      {activeTab === index && isEditNameFilter ? (
                        <>
                          <Input
                            value={valueInputFilter}
                            onChange={(e) => {
                              e.stopPropagation();
                              setValueInputFilter(e.target.value);
                            }}
                            disabled={loadingEditFilter}
                            onKeyPress={(event) => {
                              if (event.key === "Enter") {
                                handleSaveFilterEdit(tab);
                              }
                            }}
                          />
                          {loadingEditFilter ? (
                            <div>
                              <Spinner size="sm mx-1" />
                            </div>
                          ) : (
                            <i
                              className="ri-check-line style-icon-filter"
                              onClick={async (e) => {
                                e.stopPropagation();
                                handleSaveFilterEdit(tab);
                              }}
                            ></i>
                          )}
                        </>
                      ) : (
                        <span onClick={() => toggleTab(index, tab)}>{tab.name}</span>
                      )}
                      {activeTab === index && isEditNameFilter ? (
                        <></>
                      ) : (
                        <i
                          className="ri-pencil-fill style-icon-filter"
                          onClick={(e) => {
                            e.stopPropagation();
                            setIsEditNameFilter(true);
                            setActiveTab(index);
                            setValueInputFilter(tab.name);
                          }}
                        ></i>
                      )}

                      {isEditNameFilter ? (
                        <i
                          className={`ri-close-line style-icon-filter ${loadingEditFilter ? "disable-icon" : ""}`}
                          onClick={(e) => {
                            e.stopPropagation();
                            setIsEditNameFilter(false);
                            setActiveTab("0");
                          }}
                        ></i>
                      ) : (
                        <Popconfirm
                          title={t("Delete the tab")}
                          description={t("Are you sure to delete this tab?")}
                          onConfirm={async () => {
                            await saveFilterAPI.delete({ id: `${filterType}/${tab.id}` });
                            setTabs((tabs) => tabs.filter((item) => item.id !== tab.id));
                          }}
                          okText={t("Yes")}
                          cancelText={t("No")}
                        >
                          <i className=" ri-close-line style-icon-filter"></i>
                        </Popconfirm>
                      )}
                    </div>
                  )}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </CardBody>
      )}
    </>
  );
};

const RenderTagFilter = ({ dataFilter = "", item, onDelete, payload, t }) => {
  const [listDataFilterTag, setListDataFilterTag] = useState([]);
  const [listDateTag, setListDateTag] = useState([]);

  useEffect(() => {
    if (dataFilter) {
      let mapDataFilter = dataFilter?.split(",");
      if (item.remote) {
        getApi();
      } else {
        mapDataFilter = mapDataFilter?.map((itemFilter) => {
          if (item?.dataOption?.find((i) => i.id === itemFilter)) {
            return item?.dataOption?.find((i) => i.id === itemFilter)?.name;
          }
          if (!item.dataOption) {
            return itemFilter;
          }
          return item;
        });
        setListDataFilterTag(mapDataFilter);
      }
    } else {
      setListDataFilterTag([]);
    }
  }, [dataFilter]);

  useEffect(() => {
    if (item?.type === "date") {
      let startDateKey = item.id + "_from";
      let endDateKey = item.id + "_to";
      let startDateValue = payload[startDateKey]
        ? item?.formatData === "timestamp"
          ? dayjs(payload[startDateKey] * 1000).format("DD/MM/YYYY")
          : new Date(payload[startDateKey]).toLocaleDateString("en-GB")
        : null;
      let endDateValue = payload[endDateKey]
        ? item?.formatData === "timestamp"
          ? dayjs(payload[endDateKey] * 1000).format("DD/MM/YYYY")
          : new Date(payload[endDateKey]).toLocaleDateString("en-GB")
        : null;
      if (startDateValue || endDateValue) {
        setListDateTag([startDateValue ?? "Past", endDateValue ?? "now"]);
      } else {
        setListDateTag([]);
      }
    }
  }, [payload, item]);

  const getApi = async () => {
    if (dataFilter) {
      try {
        const res = await getListDataDefault(item.pathUrlLoad, { id: dataFilter });
        const mapDataFilter = (res?.items || []).map((item) => item.name || item.id);
        setListDataFilterTag(mapDataFilter);
      } catch (e) {
        console.error(e);
        setListDataFilterTag([]);
      }
    } else {
      setListDataFilterTag([]);
    }
  };
  if (dataFilter || (item.type === "date" && listDateTag.length > 0)) {
    let listName;
    if (item.type !== "date") {
      listName = listDataFilterTag.reduce((accumulator, itemTag, index) => {
        let newData = accumulator + t(itemTag);
        if (listDataFilterTag.length > 1 && index < listDataFilterTag.length - 1) {
          newData += ",";
        }
        return newData;
      }, "");
    } else {
      listName = listDateTag.reduce((accumulator, itemTag, index) => {
        let newData = accumulator + t(itemTag);
        if (index < listDateTag.length - 1) {
          newData += " - ";
        }
        return newData;
      }, "");
    }

    return (
      <Tooltip title={listName}>
        <div className="d-inline-block p-1 bg-soft-primary rounded-4 w-auto m-1">
          <div className="hstack gap-2">
            <div className="text-truncate" style={{ maxWidth: "300px" }}>
              {t(item.title)}: {listName}
            </div>
            <button
              type="button"
              className="btn btn-icon btn-ghost-primary rounded-circle"
              style={{ height: "18px", width: "18px" }}
              onClick={() => onDelete(item.id)}
            >
              <i className="ri-close-line text-primary"></i>
            </button>
          </div>
        </div>
      </Tooltip>
    );
  }
  return <Fragment></Fragment>;
};

export const FilterItemDropDown = ({
  rounded,
  handleNavigate = () => {},
  dataCheck = "",
  handleChangeFilterItem,
  dataOption = [],
  title,
  remote = false,
  type,
  pathUrlLoad,
  typeRemoveFilter,
  id,
  onResetTypeRemoveTag = () => {},
  paramsSearch = {},
  inOtherFilters = false,
  onSaveTempFilters = () => {},
  t,
}) => {
  const [value, setValue] = useState("");
  const [checkList, setCheckList] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [firstRun, setFirstRun] = useState(true);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const handleDropdownToggle = (e) => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const getListToApi = async (query = "", firstRun = false) => {
    try {
      const res = await getListDataDefault(pathUrlLoad, { limit: 3, ...(query && { query }) });
      setDataList(res?.items || []);
      setLoading(false);

      if (firstRun) {
        const keyParamSearch = Object.keys(paramsSearch).find((item) => item === id);
        if (keyParamSearch) {
          const data = paramsSearch[keyParamSearch];
          setCheckList(data?.split(","));
        }
      }
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    if (remote) {
      getListToApi("", true);
    }
  }, []);

  const getSelectedList = async () => {
    if (dataCheck) {
      try {
        const res = await getListDataDefault(pathUrlLoad, { id: dataCheck });
        setCheckList(res?.items || []);
      } catch (e) {
        console.error(e);
        setCheckList([]);
      }
    } else {
      setCheckList([]);
    }
  };

  useEffect(() => {
    if (!remote) {
      if (dataOption.length > 0) {
        const mapCheckList = dataCheck
          ? dataCheck?.split(",")?.map((item) => dataOption.find((i) => i.id === item)) || []
          : [];
        setCheckList(mapCheckList);
        setDataList(dataOption);
      }
    } else {
      getSelectedList();
    }
  }, [dataCheck, isDropdownOpen]);

  const debouncedInputValue = useDebounce(value, 600);
  useEffect(() => {
    if (firstRun) {
      setFirstRun(false);
      return;
    }
    if (remote) {
      getListToApi(debouncedInputValue);
    } else {
      if (dataOption.length > 0)
        setDataList(dataOption.filter((item) => item.name.includes(debouncedInputValue.trim())));
    }
  }, [debouncedInputValue]);

  const handleCheckList = (e, data) => {
    let newData =
      type === "selectBox"
        ? e.target.checked
          ? [...checkList, data]
          : checkList.filter((item) => item.id !== data.id).filter((item) => item !== data.id)
        : [data];
    setCheckList(newData);
    inOtherFilters && onSaveTempFilters(newData);
  };
  const handleCheckAll = (e) => {
    let newData = e.target.checked ? (remote ? dataList : dataOption.length > 0 ? dataOption : dataList) : [];
    setCheckList(newData);
    inOtherFilters && onSaveTempFilters(newData);
  };

  const handleDeleteItem = (data) => {
    const newData = checkList.filter((item) => item.id !== data);
    setCheckList(newData);
    inOtherFilters && onSaveTempFilters(newData);
  };

  const handleClickFilter = () => {
    handleChangeFilterItem(checkList);
    setIsDropdownOpen(false);
  };
  useEffect(() => {
    if (typeRemoveFilter === id) {
      setCheckList([]);
      onResetTypeRemoveTag();
    }
  }, [typeRemoveFilter, id]);

  return (
    <ButtonGroup className="d-block">
      <UncontrolledDropdown isOpen={isDropdownOpen} toggle={handleDropdownToggle}>
        <DropdownToggle
          tag="div"
          className={`btn ${
            isDropdownOpen ? "border-primary" : "btn-light border-1"
          } ${rounded} w-100 d-flex justify-content-between ${inOtherFilters ? "bg-white" : ""}`}
        >
          <div className="me-2 d-flex flex-wrap">
            {inOtherFilters
              ? checkList.length === 0
                ? `${t("Select")} ${t(title)}`
                : checkList.map((item, index) => (
                    <div key={index} className="d-inline-block p-1 bg-soft-primary rounded-4 w-auto m-1 px-2">
                      <div className="d-inline-flex">
                        <div className="text-truncate" style={{ maxWidth: "300px" }}>
                          {t(item.name)}
                        </div>
                        <button
                          type="button"
                          className="btn btn-icon btn-ghost-primary rounded-circle ms-2"
                          style={{ height: "18px", width: "18px" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteItem(item.id);
                          }}
                        >
                          <i className="ri-close-line text-primary"></i>
                        </button>
                      </div>
                    </div>
                  ))
              : t(title)}
          </div>
          <i className="mdi mdi-chevron-down"></i>
        </DropdownToggle>
        <DropdownMenu style={{ width: "250px" }}>
          <div className="d-flex form-icon m-1">
            <input
              type="text"
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
                if (remote) setLoading(true);
              }}
              style={{ paddingRight: "2.7rem" }}
              className="form-control form-control-icon"
              id="iconInput"
              placeholder={t("Search...")}
            />
            <i className="mdi mdi-magnify search-widget-icon"></i>
          </div>
          <SimpleBar style={{ maxHeight: "200px", minHeight: "100px" }}>
            {loading ? (
              <Loader />
            ) : (
              <>
                {dataList.length > 0 ? (
                  <>
                    {type === "selectBox" && (
                      <DropdownItem toggle={false} className="border-bottom">
                        <div className="form-check form-check-primary">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            id={`check-all-${title}${inOtherFilters && "-other"}`}
                            value={""}
                            onChange={(e) => {
                              handleCheckAll(e);
                            }}
                            checked={
                              (checkList.length && dataOption.length === checkList.length) ||
                              dataList
                                .map((item) => item.id)
                                .every(
                                  (item) =>
                                    checkList.findIndex((subItem) => subItem.id === item || subItem === item) > -1,
                                )
                            }
                          />
                          <Label
                            className="form-check-label w-100"
                            for={`check-all-${title}${inOtherFilters && "-other"}`}
                          >
                            {t("Check All")}
                          </Label>
                        </div>
                      </DropdownItem>
                    )}
                    {dataList.map((option, key) => (
                      <DropdownItem key={key} toggle={false}>
                        <Tooltip title={option.name ? t(option.name) : option.id}>
                          <div className="form-check form-check-primary">
                            <Input
                              type="checkbox"
                              id={`${option.id}${inOtherFilters && "-other"}`}
                              value={option.id}
                              onChange={(e) => {
                                handleCheckList(e, option);
                              }}
                              checked={
                                checkList.findIndex(
                                  (item) => item?.id === option?.id || item === option?.id || item === option?.name,
                                ) > -1
                              }
                            />
                            <Label
                              className="form-check-label w-100 text-truncate"
                              for={`${option.id}${inOtherFilters && "-other"}`}
                            >
                              {option.name ? t(option.name) : option.id}
                            </Label>
                          </div>
                        </Tooltip>
                      </DropdownItem>
                    ))}
                  </>
                ) : (
                  <div className="text-center">
                    <div>
                      <lord-icon
                        src="https://cdn.lordicon.com/msoeawqm.json"
                        trigger="loop"
                        colors="primary:#405189,secondary:#0ab39c"
                        style={{ width: "42px", height: "42px" }}
                      ></lord-icon>
                    </div>

                    <div>
                      <p>{t("Sorry! No Result Found")}</p>
                    </div>
                  </div>
                )}
              </>
            )}
          </SimpleBar>
          {!inOtherFilters && (
            <div className="d-flex flex-wrap w-100">
              <button className="btn btn-primary m-1 w-100 mx-3" type="button" onClick={handleClickFilter}>
                <span>{t("Filter")}</span>
              </button>
            </div>
          )}
        </DropdownMenu>
      </UncontrolledDropdown>
    </ButtonGroup>
  );
};

export const DateDropDown = ({
  t,
  title,
  rounded,
  handleChangeDate = () => {},
  inOtherFilters = false,
  isOtherFiltersOpen = false,
  onSaveTempFilters = () => {},
  savedStartDate = null,
  savedEndDate = null,
  payload = {},
}) => {
  const dateFilterOptions = ["Today", "Yesterday", "Last week", "This week", "Last month", "This month"];

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedButton, setSelectedButton] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleClickButton = (data) => {
    if (selectedButton !== data) {
      setSelectedButton(data);
      if (inOtherFilters) {
        const filterDate = exchangeStringTimeToValidTime(data);
        data !== "Customize" && onSaveTempFilters(filterDate);
      }
    } else {
      setSelectedButton("");
      if (inOtherFilters) {
        onSaveTempFilters(null);
      }
    }
  };
  const clickPositionRef = useRef(null);

  const toggle = () => {
    if (clickPositionRef.current !== "insideFlatpickr") {
      setIsDropdownOpen(!isDropdownOpen);
    }
  };

  const handleCalendarOpen = () => {
    clickPositionRef.current = "insideFlatpickr";
  };
  const handleCalendarClose = () => {
    clickPositionRef.current = null;
  };

  const exchangeValidTimeToStringTime = () => {
    let now = new Date();
    const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const endOfToday = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
    const yesterday = new Date(startOfDay);
    yesterday.setDate(now.getDate() - 1);

    const startOfLastWeek = new Date(startOfDay);
    startOfLastWeek.setDate(startOfDay.getDate() - (startOfDay.getDay() - 1) - 7); // Calculate the start of last week
    const endOfLastWeek = new Date(startOfLastWeek);
    endOfLastWeek.setDate(startOfLastWeek.getDate() + 7);

    const startOfWeek = new Date(startOfDay);
    startOfWeek.setDate(startOfDay.getDate() - (startOfDay.getDay() - 1));
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(endOfWeek.getDate() + 7);

    const startOfLastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1); // Calculate the start of last month
    const endOfLastMonth = new Date(now.getFullYear(), now.getMonth(), 1); // Calculate the end of last month

    const startOfThisMonth = new Date(now.getFullYear(), now.getMonth(), 1); // Calculate the start of this month
    const endOfThisMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1); // Calculate the end of this month
    if (savedStartDate || savedEndDate) {
      if (
        formatDate1(savedStartDate) === formatDate1(startOfDay) &&
        formatDate1(savedEndDate) === formatDate1(endOfToday)
      ) {
        setSelectedButton("Today");
      } else if (
        formatDate1(savedStartDate) === formatDate1(yesterday) &&
        formatDate1(savedEndDate) === formatDate1(startOfDay)
      ) {
        setSelectedButton("Yesterday");
      } else if (
        formatDate1(savedStartDate) === formatDate1(startOfLastWeek) &&
        formatDate1(savedEndDate) === formatDate1(endOfLastWeek)
      ) {
        setSelectedButton("Last week");
      } else if (
        formatDate1(savedStartDate) === formatDate1(startOfWeek) &&
        formatDate1(savedEndDate) === formatDate1(endOfWeek)
      ) {
        setSelectedButton("This week");
      } else if (
        formatDate1(savedStartDate) === formatDate1(startOfLastMonth) &&
        formatDate1(savedEndDate) === formatDate1(endOfLastMonth)
      ) {
        setSelectedButton("Last month");
      } else if (
        formatDate1(savedStartDate) === formatDate1(startOfThisMonth) &&
        formatDate1(savedEndDate) === formatDate1(endOfThisMonth)
      ) {
        setSelectedButton("This month");
      } else {
        setSelectedButton("Customize");
      }
    } else {
      setSelectedButton("");
    }
  };

  const exchangeStringTimeToValidTime = (data) => {
    let filterDate = null;
    let now = new Date();
    const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    switch (data || selectedButton) {
      case "Today": {
        const endOfToday = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
        filterDate = [startOfDay, endOfToday];
        break;
      }
      case "Yesterday": {
        const yesterday = new Date(startOfDay);
        yesterday.setDate(now.getDate() - 1);
        filterDate = [yesterday, startOfDay];
        break;
      }
      case "Last week": {
        const startOfLastWeek = new Date(startOfDay);
        startOfLastWeek.setDate(startOfDay.getDate() - (startOfDay.getDay() - 1) - 7); // Calculate the start of last week
        const endOfLastWeek = new Date(startOfLastWeek);
        endOfLastWeek.setDate(startOfLastWeek.getDate() + 7);
        filterDate = [startOfLastWeek, endOfLastWeek];
        break;
      }
      case "This week": {
        const startOfWeek = new Date(startOfDay);
        startOfWeek.setDate(startOfDay.getDate() - (startOfDay.getDay() - 1));
        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(endOfWeek.getDate() + 7);
        filterDate = [startOfWeek, endOfWeek];
        break;
      }
      case "Last month": {
        const startOfLastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1); // Calculate the start of last month
        const endOfLastMonth = new Date(now.getFullYear(), now.getMonth(), 1); // Calculate the end of last month
        filterDate = [startOfLastMonth, endOfLastMonth];
        break;
      }
      case "This month": {
        const startOfThisMonth = new Date(now.getFullYear(), now.getMonth(), 1); // Calculate the start of this month
        const endOfThisMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1); // Calculate the end of this month
        filterDate = [startOfThisMonth, endOfThisMonth];
        break;
      }
      case "Customize": {
        if (startDate || endDate) {
          filterDate = [startDate?.[0] || null, endDate?.[0] || null];
        } else {
          filterDate = null;
        }
        break;
      }
      default:
    }
    return filterDate;
  };

  const handleClickFilter = () => {
    const filterDate = exchangeStringTimeToValidTime();
    console.log(filterDate);
    handleChangeDate(filterDate);
    toggle();
  };

  useEffect(() => {
    setStartDate(savedStartDate ? [savedStartDate] : null);
    exchangeValidTimeToStringTime();
  }, [savedStartDate]);

  useEffect(() => {
    setEndDate(savedEndDate ? [savedEndDate] : null);
    exchangeValidTimeToStringTime();
  }, [savedEndDate]);

  useEffect(() => {
    exchangeValidTimeToStringTime();
  }, [isDropdownOpen]);

  return (
    <ButtonGroup className="d-block">
      <UncontrolledDropdown isOpen={isDropdownOpen} toggle={toggle}>
        <DropdownToggle
          tag="div"
          className={`btn ${
            isDropdownOpen ? "border-primary" : "btn-light border-1"
          } w-100 d-flex justify-content-between ${rounded} ${inOtherFilters ? "bg-white" : ""}`}
        >
          <div className="me-2">
            {inOtherFilters
              ? selectedButton
                ? selectedButton !== "Customize"
                  ? t(selectedButton)
                  : `${startDate ? new Date(startDate).toLocaleDateString("en-GB") : t("Past")} - ${
                      endDate ? new Date(endDate).toLocaleDateString("en-GB") : t("now")
                    }`
                : `${t("Select")} ${t(title)}`
              : t(title)}
          </div>
          <i className="mdi mdi-chevron-down"></i>
        </DropdownToggle>
        <DropdownMenu
          className="p-3"
          style={{
            width: "400px",
          }}
        >
          <div className="d-flex flex-wrap w-100">
            {dateFilterOptions.map((item, key) => (
              <button
                key={key}
                className={`btn btn-light m-1 waves-effect ${selectedButton === item ? "text-primary " : ""}`}
                style={{ width: "calc(50% - 0.5rem)" }}
                type="button"
                onClick={() => {
                  handleClickButton(item);
                }}
              >
                <span>{t(item)}</span>
              </button>
            ))}
            <button
              className={`btn m-1 btn-light waves-effect w-100 ${
                selectedButton === "Customize" ? "text-primary " : ""
              }`}
              type="button"
              onClick={() => {
                handleClickButton("Customize");
              }}
            >
              <span>{t("Customize")}</span>
            </button>
          </div>
          {selectedButton === "Customize" && (
            <div className="hstack mt-3">
              <div className="m-1 flex-grow-1">
                <Flatpickr
                  value={startDate}
                  onChange={(date) => {
                    setStartDate(date.length > 0 ? date : null);
                    if (isOtherFiltersOpen && inOtherFilters) {
                      let data =
                        (date.length > 0 ? date : null) || endDate
                          ? [date.length > 0 ? date?.[0] : null, endDate?.[0] || null]
                          : null;
                      onSaveTempFilters(data);
                    }
                  }}
                  className="form-control"
                  placeholder={t("Select a date")}
                  options={{
                    altInput: true,
                    altFormat: "F j, Y",
                    dateFormat: "d.m.y",
                    onOpen: handleCalendarOpen,
                    onClose: handleCalendarClose,
                    disable: [
                      function (date) {
                        // return true to disable
                        if (endDate) {
                          return date >= endDate[0];
                        }
                      },
                    ],
                  }}
                />
              </div>
              <div className="flex-shrink-0 d-flex align-items-center">
                <i className="bx bx-minus"></i>
              </div>
              <div className="m-1 flex-grow-1">
                <Flatpickr
                  value={endDate}
                  onChange={(date) => {
                    setEndDate(date.length > 0 ? date : null);
                    if (isOtherFiltersOpen && inOtherFilters) {
                      let data =
                        (date.length > 0 ? date : null) || startDate
                          ? [startDate?.[0] || null, date.length > 0 ? date?.[0] : null]
                          : null;
                      onSaveTempFilters(data);
                    }
                  }}
                  className="form-control"
                  placeholder={t("Select a date")}
                  options={{
                    altInput: true,
                    altFormat: "F j, Y",
                    dateFormat: "d.m.y",
                    onOpen: handleCalendarOpen,
                    onClose: handleCalendarClose,
                    disable: [
                      function (date) {
                        // return true to disable
                        if (startDate) {
                          return date <= startDate[0];
                        }
                      },
                    ],
                  }}
                />
              </div>
            </div>
          )}
          {!inOtherFilters && (
            <div className="d-flex flex-wrap w-100 mt-3">
              <button className="btn btn-primary m-1 w-100" type="button" onClick={handleClickFilter}>
                <span>{t("Filter")}</span>
              </button>
            </div>
          )}
        </DropdownMenu>
      </UncontrolledDropdown>
    </ButtonGroup>
  );
};

const OtherFilters = ({ buttonClass, listFilterType, payload = {}, setPayload, t }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleClearFilter = () => {
    setPayloadFilter({});
  };
  const handleClickFilter = () => {
    setPayload(payloadFilter);
    toggle();
  };

  const [payloadFilter, setPayloadFilter] = useState({});

  useEffect(() => {
    setPayloadFilter(isOpen ? payload : {});
  }, [payload, isOpen]);

  return (
    <Fragment>
      <ButtonTheme type="button" onClick={toggle} className={buttonClass}>
        <span className="me-2">{t("Other filters")}</span>
        <i className="ri-equalizer-fill ms-1 align-bottom"></i>
      </ButtonTheme>
      <Offcanvas isOpen={isOpen} toggle={toggle} direction="end" className="offcanvas-end border-0">
        <OffcanvasHeader
          className="d-flex align-items-center bg-primary bg-gradient p-3 offcanvas-header-dark"
          toggle={toggle}
        >
          <span className="m-0 me-2 text-white">{t("Other Filters")}</span>
        </OffcanvasHeader>
        <OffcanvasBody>
          {listFilterType.map((item, index) => {
            let startDateKey = item.id + "_from";
            let endDateKey = item.id + "_to";
            return (
              <div key={index} className="mb-3">
                <Label className="fw-semibold">{t(item.title)}</Label>
                {item?.type !== "date" ? (
                  <FilterItemDropDown
                    t={t}
                    rounded={`rounded`}
                    dataOption={item?.dataOption || []}
                    title={item?.title}
                    dataCheck={payloadFilter[item.id]}
                    remote={item.remote}
                    pathUrlLoad={item.pathUrlLoad}
                    inOtherFilters={true}
                    type={item?.type}
                    onSaveTempFilters={(dataCheck) => {
                      setPayloadFilter((dataPayload) => {
                        const newData = { ...dataPayload };
                        if (dataCheck.length > 0) {
                          newData[item.id] = dataCheck.map((i) => i?.id).join();
                        } else {
                          delete newData[item.id];
                        }
                        return newData;
                      });
                    }}
                  />
                ) : (
                  <DateDropDown
                    t={t}
                    title={item?.title}
                    rounded={`rounded`}
                    inOtherFilters={true}
                    isOtherFiltersOpen={isOpen}
                    onSaveTempFilters={(data) => {
                      setPayloadFilter((dataPayload) => {
                        const newData = { ...dataPayload };
                        if (data && data.length > 0) {
                          newData[startDateKey] = data?.[0] && data?.[0] ? formatDate1(data[0].toISOString()) : "";
                          newData[endDateKey] = data?.[1] && data?.[1] ? formatDate1(data[1].toISOString()) : "";
                        } else {
                          delete newData[startDateKey];
                          delete newData[endDateKey];
                        }
                        return newData;
                      });
                    }}
                    savedStartDate={payloadFilter[startDateKey] ? new Date(payloadFilter[startDateKey]) : null}
                    savedEndDate={payloadFilter[endDateKey] ? new Date(payloadFilter[endDateKey]) : null}
                    payload={payloadFilter}
                  />
                )}
              </div>
            );
          })}
        </OffcanvasBody>
        <div className="offcanvas-footer border-top p-3 text-center">
          <div className="d-flex">
            <div style={{ flex: 1 }}>
              <ButtonTheme type="button" className="btn btn-outline-black " onClick={handleClearFilter}>
                {t("Clear Filters")}
              </ButtonTheme>
            </div>
            <div style={{ flex: 2 }}>
              <ButtonTheme type="button" className="btn btn-primary w-100" onClick={handleClickFilter}>
                {t("Filter")}
              </ButtonTheme>
            </div>
          </div>
        </div>
      </Offcanvas>
    </Fragment>
  );
};

export default withTranslation()(FilterComponent);
