import React from "react";
import { withTranslation } from "react-i18next";
import { Col, Container, Row } from "reactstrap";

const Footer = ({ t, isLayout }) => {
  return (
    <React.Fragment>
      <footer className="footer" style={{ ...(!isLayout && { left: 0 }) }}>
        <Container fluid>
          <Row>
            <Col sm={6}>
              {new Date().getFullYear()} ©{" "}
              <a href="https://onexapis.com/" target="_blank" rel="noreferrer">
                OneXAPIs
              </a>
              .
            </Col>
            <Col sm={6}>
              <div className="text-sm-end d-none d-sm-block">
                <a href="https://onexapis.com/" target="_blank" rel="noreferrer">
                  {t("Design & Develop by OneXAPIs team")}{" "}
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default withTranslation()(Footer);
