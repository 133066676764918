import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "reactstrap";

const SearchInput = ({
  searchValue,
  onChangeValue,
  placeholder,
  toggleOn = () => {},
  onDeleteSearchValue = () => {},
}) => {
  const { t } = useTranslation();
  const [showDeleteSearchValue, setShowDeleteSearchValue] = useState(false);
  useEffect(() => {
    setShowDeleteSearchValue(!!searchValue);
  }, [searchValue]);
  const handleDeleteSearchValue = () => {
    onDeleteSearchValue();
    onChangeValue("");
    setShowDeleteSearchValue(false);
  };
  return (
    <div className="app-search d-md-block p-0">
      <div className="position-relative">
        <Input
          id="search-input"
          type="text"
          autoComplete="off"
          className="form-control border"
          placeholder={t(placeholder)}
          value={searchValue}
          onClick={toggleOn}
          onChange={(e) => {
            onChangeValue(e.target.value);
          }}
        />
        <span className="mdi mdi-magnify search-widget-icon"></span>
        {showDeleteSearchValue && (
          <span
            className="mdi mdi-close-circle search-widget-icon search-widget-icon-close cursor-pointer"
            onClick={handleDeleteSearchValue}
          ></span>
        )}
      </div>
    </div>
  );
};
export default SearchInput;
