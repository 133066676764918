import React from "react";

//import Scss
import "./assets/scss/themes.scss";

//imoprt Route
import Route from "./Routes";
import { defineElement } from "lord-icon-element";
import lottie from "lottie-web";
import { useLocation } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import { useEffect } from "react";
import i18n from "./i18n";
import languages, { DEFAULT_LANGUAGES } from "./common/languages";
import fakeBackend from "./helpers/AuthType/fakeBackend";
defineElement(lottie.loadAnimation);

fakeBackend();
function App() {
  // Activating fake backend
  const { pathname } = useLocation();

  useEffect(() => {
    const pathArr = pathname.split("/");
    const listLanguages = Object.keys(languages);
    if (listLanguages.includes(pathArr[1])) {
      localStorage.setItem("I18N_LANGUAGE", pathArr[1]);
      i18n.changeLanguage(pathArr[1]);
    } else {
      const userLanguage = navigator.language || navigator.userLanguage;
      if (Object.keys(languages).includes(userLanguage)) {
        localStorage.setItem("I18N_LANGUAGE", userLanguage);
        i18n.changeLanguage(userLanguage);
      } else {
        localStorage.setItem("I18N_LANGUAGE", DEFAULT_LANGUAGES);
        i18n.changeLanguage(DEFAULT_LANGUAGES);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Route />
      <ToastContainer />
    </React.Fragment>
  );
}

export default App;
