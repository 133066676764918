import { subscriptionApi } from "../../helpers/resource_helper";
import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import {
  addSubscriptionFail,
  addSubscriptionSuccess,
  getSubscriptionFail,
  getSubscriptionSuccess,
  listSubscriptionFail,
  listSubscriptionSuccess,
  pauseSubscriptionFail,
  pauseSubscriptionSuccess,
  resumeSubscriptionFail,
  resumeSubscriptionSuccess,
  suspendSubscriptionFail,
  suspendSubscriptionSuccess,
  updateSubscriptionFail,
  updateSubscriptionSuccess,
} from "./actions";
import {
  ADD_SUBSCRIPTION,
  GET_SUBSCRIPTION,
  LIST_SUBSCRIPTION,
  PAUSE_SUBSCRIPTION,
  RESUME_SUBSCRIPTION,
  SUSPEND_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION,
} from "./actionTypes";
import { toast } from "react-toastify";

function* listSubscriptionSaga({ payload }) {
  try {
    const response = yield call(subscriptionApi.list, payload);
    yield put(listSubscriptionSuccess(response));
  } catch (error) {
    yield put(listSubscriptionFail(error));
  }
}

function* getSubscriptionSaga({ payload: id }) {
  try {
    const response = yield call(subscriptionApi.get, { id });
    yield put(getSubscriptionSuccess(response));
  } catch (error) {
    yield put(getSubscriptionFail(error));
  }
}

function* addSubscriptionSaga({ payload: { payload, handleAfterSubmit } }) {
  try {
    const response = yield call(subscriptionApi.create, payload);
    yield put(addSubscriptionSuccess(response));
    yield call(handleAfterSubmit);
    toast.success("Add subscription success!");
  } catch (error) {
    yield put(addSubscriptionFail(error));
    toast.error("Add subscription failed!");
  }
}

function* updateSubscriptionSaga({ payload: { payload, handleAfterSubmit } }) {
  try {
    const response = yield call(subscriptionApi.put, payload);
    yield put(updateSubscriptionSuccess(response));
    yield call(handleAfterSubmit);
    toast.success("Update subscription success!");
  } catch (error) {
    yield put(updateSubscriptionFail(error));
    toast.error("Update subscription failed!");
  }
}

function* suspendSubscriptionSaga({ payload }) {
  try {
    const response = yield call(subscriptionApi.createCustomPath, `${payload.id}/suspend`, {});
    yield put(suspendSubscriptionSuccess(payload));
  } catch (error) {
    yield put(suspendSubscriptionFail(error));
  }
}
function* resumeSubscriptionSaga({ payload }) {
  try {
    const response = yield call(subscriptionApi.createCustomPath, `${payload.id}/resume`, {});
    yield put(resumeSubscriptionSuccess(payload));
  } catch (error) {
    yield put(resumeSubscriptionFail(error));
  }
}
function* pauseSubscriptionSaga({ payload }) {
  try {
    const response = yield call(subscriptionApi.createCustomPath, `${payload.id}/pause`, {});
    yield put(pauseSubscriptionSuccess(payload));
  } catch (error) {
    yield put(pauseSubscriptionFail(error));
  }
}

export function* watchListSubscription() {
  yield takeEvery(LIST_SUBSCRIPTION, listSubscriptionSaga);
}

export function* watchGetSubscription() {
  yield takeEvery(GET_SUBSCRIPTION, getSubscriptionSaga);
}

export function* watchAddSubscription() {
  yield takeEvery(ADD_SUBSCRIPTION, addSubscriptionSaga);
}

export function* watchUpdateSubscription() {
  yield takeEvery(UPDATE_SUBSCRIPTION, updateSubscriptionSaga);
}

export function* watchSuspendSubscription() {
  yield takeEvery(SUSPEND_SUBSCRIPTION, suspendSubscriptionSaga);
}
export function* watchResumeSubscription() {
  yield takeEvery(RESUME_SUBSCRIPTION, resumeSubscriptionSaga);
}
export function* watchPauseSubscription() {
  yield takeEvery(PAUSE_SUBSCRIPTION, pauseSubscriptionSaga);
}

function* subscriptionSaga() {
  yield all([
    fork(watchListSubscription),
    fork(watchGetSubscription),
    fork(watchAddSubscription),
    fork(watchUpdateSubscription),
    fork(watchSuspendSubscription),
    fork(watchResumeSubscription),
    fork(watchPauseSubscription),
  ]);
}

export default subscriptionSaga;
