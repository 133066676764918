import React, { useEffect } from "react";
import withRouter from "../Components/Common/withRouter";
import useMemoizedSelector from "../Components/Hooks/useMemoizedSelector";
import { useProfile } from "../Components/Hooks/UserHooks";
import { useNavigate } from "react-router-dom";

const NonAuthLayout = ({ children }) => {
  const { userProfile, token } = useProfile();
  const navigate = useNavigate();
  const { layoutModeType } = useMemoizedSelector((state) => ({
    layoutModeType: state.Layout.layoutModeType,
  }));

  useEffect(() => {
    if (userProfile && token) {
      navigate("/dashboard");
    }
  }, [userProfile, token]);

  useEffect(() => {
    if (layoutModeType === "dark") {
      document.body.setAttribute("data-layout-mode", "dark");
    } else {
      document.body.setAttribute("data-layout-mode", "light");
    }
    return () => {
      document.body.removeAttribute("data-layout-mode");
    };
  }, [layoutModeType]);
  return <div>{children}</div>;
};

export default withRouter(NonAuthLayout);
