import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Card, Col, Container, Row, Form, Label, Input, FormFeedback, Alert, CardBody } from "reactstrap";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

//formik
import { useFormik } from "formik";
import * as Yup from "yup";

import ButtonTheme from "../../Components/Common/ButtonTheme";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { useProfile } from "../../Components/Hooks/UserHooks";
import onexapisLogo from "../../assets/images/onexapis-logo.png";
import useMemoizedSelector from "../../Components/Hooks/useMemoizedSelector";

import { changePass, resetChangePassFlag } from "../../store/actions";

const ChangePasswordPage = ({ t }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useNavigate();
  const { userProfile } = useProfile();

  const [oldPasswordShow, setOldPasswordShow] = useState(false);
  const [newPasswordShow, setNewPasswordShow] = useState(false);
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      password: "",
      newPassword: "",
      confirm_password: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(8, t("Password must be at least 8 characters"))
        .matches(RegExp("(.*[a-z].*)"), t("At least lowercase letter"))
        .matches(RegExp("(.*[A-Z].*)"), t("At least uppercase letter"))
        .matches(RegExp("(.*[0-9].*)"), t("At least one number"))
        .matches(/[/\W|_/g]/, "Password requires a symbol")
        .required(t("Please Enter Your Old Password")),
      newPassword: Yup.string()
        .min(8, t("Password must be at least 8 characters"))
        .matches(RegExp("(.*[a-z].*)"), t("At least lowercase letter"))
        .matches(RegExp("(.*[A-Z].*)"), t("At least uppercase letter"))
        .matches(RegExp("(.*[0-9].*)"), t("At least one number"))
        .matches(/[/\W|_/g]/, "Password requires a symbol")
        .required(t("Please Enter Your New Password")),
      confirm_password: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], t("Passwords must match"))
        .required(t("Confirm password is required")),
    }),
    onSubmit: (values) => {
      const dataPayload = {
        username: location.state.username,
        password: values.password,
        newPassword: values.newPassword,
      };
      dispatch(changePass(dataPayload, history));
    },
  });
  const { error, loading, changePassMsg } = useMemoizedSelector((state) => ({
    changePassMsg: state.ChangePassword.changePassMsg,
    error: state.ChangePassword.error,
    loading: state.ChangePassword.loading,
  }));

  useEffect(() => {
    if (!loading && !!error) {
      let resetMsgTime = setTimeout(() => {
        dispatch(resetChangePassFlag());
      }, 3000);
      return () => clearTimeout(resetMsgTime);
    }
  }, [dispatch, error, loading]);

  document.title = `${t("Change Password")} | OneXAPIs`;

  if (userProfile) {
    history("/my-drive");
  }

  return (
    <ParticlesAuth>
      <div className="auth-page-content">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center mt-sm-0 mb-0 text-white-50">
                <div>
                  <Link to="/#" className="d-inline-block auth-logo">
                    <img src={onexapisLogo} alt="" height="100" />
                  </Link>
                </div>
                <p className="mt-3 fs-15 fw-medium">All-in-One Automation and Integration Solution</p>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="mt-4">
                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-warning">{t("Change password")}</h5>
                    <p className="text-muted">{t("You have to change password on first login.")}</p>
                  </div>
                  <div className="p-2">
                    {error && error ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {t(error)}
                      </Alert>
                    ) : null}
                    {changePassMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {t(changePassMsg)}
                      </Alert>
                    ) : null}
                    <Form onSubmit={validation.handleSubmit}>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="password-input">
                          {t("Password")}
                        </Label>
                        <div className="position-relative auth-pass-inputgroup">
                          <Input
                            type={oldPasswordShow ? "text" : "password"}
                            className="form-control pe-5 password-input"
                            placeholder={t("Enter old password")}
                            id="password-input"
                            name="password"
                            value={validation.values.password}
                            onBlur={validation.handleBlur}
                            onChange={validation.handleChange}
                            invalid={validation.errors.password && validation.touched.password ? true : false}
                          />
                          {validation.errors.password && validation.touched.password ? (
                            <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                          ) : null}
                          <Button
                            color="link"
                            onClick={() => setOldPasswordShow(!oldPasswordShow)}
                            className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                            id="password-addon"
                          >
                            <i className="ri-eye-fill align-middle"></i>
                          </Button>
                        </div>
                      </div>

                      <div className="mb-3">
                        <Label className="form-label" htmlFor="new-password-input">
                          {t("New Password")}
                        </Label>
                        <div className="position-relative auth-pass-inputgroup">
                          <Input
                            type={newPasswordShow ? "text" : "password"}
                            className="form-control pe-5 password-input"
                            placeholder={t("Enter new password")}
                            id="new-password-input"
                            name="newPassword"
                            value={validation.values.newPassword}
                            onBlur={validation.handleBlur}
                            onChange={validation.handleChange}
                            invalid={validation.errors.newPassword && validation.touched.newPassword ? true : false}
                          />
                          {validation.errors.newPassword && validation.touched.newPassword ? (
                            <FormFeedback type="invalid">{validation.errors.newPassword}</FormFeedback>
                          ) : null}
                          <Button
                            color="link"
                            onClick={() => setNewPasswordShow(!newPasswordShow)}
                            className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                            id="password-addon"
                          >
                            <i className="ri-eye-fill align-middle"></i>
                          </Button>
                        </div>
                      </div>

                      <div className="mb-3">
                        <Label className="form-label" htmlFor="confirm-password-input">
                          {t("Confirm Password")}
                        </Label>
                        <div className="position-relative auth-pass-inputgroup mb-3">
                          <Input
                            type={confirmPasswordShow ? "text" : "password"}
                            className="form-control pe-5 password-input"
                            placeholder={t("Confirm password")}
                            id="confirm-password-input"
                            name="confirm_password"
                            value={validation.values.confirm_password}
                            onBlur={validation.handleBlur}
                            onChange={validation.handleChange}
                            invalid={
                              validation.errors.confirm_password && validation.touched.confirm_password ? true : false
                            }
                          />
                          {validation.errors.confirm_password && validation.touched.confirm_password ? (
                            <FormFeedback type="invalid">{validation.errors.confirm_password}</FormFeedback>
                          ) : null}
                          <Button
                            color="link"
                            onClick={() => setConfirmPasswordShow(!confirmPasswordShow)}
                            className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                          >
                            <i className="ri-eye-fill align-middle"></i>
                          </Button>
                        </div>
                      </div>

                      <div className="mt-4">
                        <ButtonTheme className="btn btn-warning w-100" type="submit" loading={loading}>
                          Change Password
                        </ButtonTheme>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-4 text-center">
                <p className="mb-0">
                  {t("Wait, I want to log in another account")}...{" "}
                  <Link to="/login" className="fw-semibold text-warning text-decoration-underline">
                    {t("Click here")}{" "}
                  </Link>{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </ParticlesAuth>
  );
};

ChangePasswordPage.propTypes = {
  history: PropTypes.object,
  username: PropTypes.string,
};

export default withTranslation()(ChangePasswordPage);
