import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

import dayjs from "dayjs";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { DatePicker } from "antd";
import { Label } from "reactstrap";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { uploadImageApi } from "../../../helpers/resource_helper";
import ModalContainer from "../../../Components/Common/ModalContainer";
import ButtonTheme from "../../../Components/Common/ButtonTheme";
import SelectTheme from "../../../Components/Common/SelectTheme";
import useMemoizedSelector from "../../../Components/Hooks/useMemoizedSelector";

import { addSubscription, getSubscription, resetSubscription, updateSubscription } from "../../../store/actions";
import InputTheme from "../../../Components/Common/InputTheme";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useTranslation } from "react-i18next";

const { RangePicker } = DatePicker;

export default function ModalSubscription({ open, toggle, selectService, selectPlan, dataSubscription = {} }) {
  const { t } = useTranslation();
  const {
    subscription,
    subscriptionLoading,
    subscriptionLimit,
    subscriptionPage,
    subscriptionTotal,
    subscriptionSuccess,
    subscriptionError,
  } = useMemoizedSelector((state) => ({
    subscription: state.Subscriptions.subscription,
    subscriptionLoading: state.Subscriptions.loading,
    subscriptionLimit: state.Subscriptions.limit,
    subscriptionPage: state.Subscriptions.page,
    subscriptionTotal: state.Subscriptions.total,
    subscriptionSuccess: state.Subscriptions.success,
    subscriptionError: state.Subscriptions.error,
  }));

  const rangePresets = [
    {
      label: "7 Days",
      value: [dayjs(), dayjs().add(7, "d")],
    },
    {
      label: "14 Days",
      value: [dayjs(), dayjs().add(14, "d")],
    },
    {
      label: "30 Days",
      value: [dayjs(), dayjs().add(30, "d")],
    },
    {
      label: "90 Days",
      value: [dayjs(), dayjs().add(90, "d")],
    },
  ];
  const refForm = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    if (open) {
      if (dataSubscription?.id) {
        dispatch(getSubscription(dataSubscription?.id));
      } else {
        dispatch(resetSubscription());
      }
    }
  }, [open]);

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });
  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          loader.file
            .then(async (file) => {
              const payload = {
                prefix: "subscription",
                name: file.name,
                image: await toBase64(file),
              };
              const res = await uploadImageApi(payload);
              resolve({ default: res.url });
            })
            .catch((err) => {
              console.error(err);
              reject(err);
            });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    if (editor?.plugins) {
      editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
        return uploadAdapter(loader);
      };
    }
  }

  const formValidation = {
    initialValues: {
      activation_date: subscription?.activation_date,
      expires_date: subscription?.expires_date,
      company_id: subscription?.company_id,
      contract_content: subscription?.contract_content || "",
      contract_annex: subscription?.contract_annex || "",
    },
    validationSchema: Yup.object({
      company_id: Yup.string().required(t("Please Select Company")),
    }),
    onSubmit: (values, actions) => {
      if (dataSubscription.id) {
        const payload = {
          ...subscription,
          ...values,
        };
        dispatch(updateSubscription(payload, toggle));
      } else {
        const payload = {
          ...values,
          service_id: selectService.id,
          plan: {
            ...selectPlan,
          },
        };
        dispatch(addSubscription(payload, toggle));
      }
    },
  };
  return (
    <ModalContainer
      isOpen={open}
      size="lg"
      title={dataSubscription?.id ? "Edit Subscription" : "Add Subscription"}
      toggle={toggle}
      actionCustom={
        <div className="hstack gap-2 justify-content-end mt-3">
          <ButtonTheme
            className="btn btn-warning w-sm"
            loading={subscriptionLoading}
            onClick={() => refForm.current.handleSubmit()}
          >
            {dataSubscription?.id ? "Edit" : "Create"}
          </ButtonTheme>
          <ButtonTheme
            type="button"
            className="btn btn-danger w-sm mx-2"
            onClick={() => {
              toggle();
            }}
          >
            Close
          </ButtonTheme>
        </div>
      }
    >
      <Formik
        enableReinitialize={true}
        initialValues={formValidation.initialValues}
        onSubmit={formValidation.onSubmit}
        innerRef={refForm}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <div className="mb-3">
              <Label className="d-block">Date</Label>
              <RangePicker
                value={[
                  values.activation_date ? dayjs(values.activation_date) : null,
                  values.expires_date ? dayjs(values.expires_date) : null,
                ]}
                style={{ width: "100%", marginBottom: "8px" }}
                presets={rangePresets}
                size="large"
                onChange={(dates, dateStrings) => {
                  if (dates) {
                    setFieldValue("activation_date", dates[0].format("YYYY-MM-DDTHH:mm:ssZ"));
                    setFieldValue("expires_date", dates[1].format("YYYY-MM-DDTHH:mm:ssZ"));
                  } else {
                    setFieldValue("activation_date", null);
                    setFieldValue("expires_date", null);
                  }
                }}
              />
            </div>
            {!dataSubscription?.id && (
              <SelectTheme className="" label={"Company"} name="company_id" remote={true} path="admin/companies" />
            )}
            <div className="mb-3">
              <Label>Contract content</Label>
              <div
                style={{
                  border: `1px solid #d1d1d1`,
                  backgroundColor: "#fff",
                }}
              >
                <CKEditor
                  config={{
                    extraPlugins: [uploadPlugin],
                  }}
                  editor={Editor}
                  watchdogConfig={false}
                  name="contract_content"
                  onReady={(editor) => {
                    editor.ui
                      .getEditableElement()
                      .parentElement.insertBefore(editor.ui.view.toolbar.element, editor.ui.getEditableElement());
                  }}
                  data={values?.contract_content}
                  onChange={(event, editor) => {
                    const data = editor?.getData();
                    setFieldValue("contract_content", data);
                  }}
                />
              </div>
            </div>
            <div className="mb-3">
              <Label>Contract annex</Label>
              <div
                style={{
                  border: `1px solid #d1d1d1`,
                  backgroundColor: "#fff",
                }}
              >
                <CKEditor
                  config={{
                    extraPlugins: [uploadPlugin],
                  }}
                  editor={Editor}
                  watchdogConfig={false}
                  name="contract_annex"
                  onReady={(editor) => {
                    editor.ui
                      .getEditableElement()
                      .parentElement.insertBefore(editor.ui.view.toolbar.element, editor.ui.getEditableElement());
                  }}
                  data={values?.contract_annex}
                  onChange={(event, editor) => {
                    const data = editor?.getData();
                    setFieldValue("contract_annex", data);
                  }}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </ModalContainer>
  );
}
