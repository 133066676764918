import React, { useEffect, useState } from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { get } from "lodash";
import moment from "moment";
import "moment/locale/vi";
import "moment/locale/en-nz";
//i18n
import i18n from "../../i18n";
import languages, { DEFAULT_LANGUAGES } from "../../common/languages";
import { Link, useLocation } from "react-router-dom";

const LanguageDropdown = ({ isWebsite }) => {
  // Declare a new state variable, which we'll call "menu"
  const [selectedLang, setSelectedLang] = useState("");
  const { pathname } = useLocation();
  const currentLanguage = localStorage.getItem("I18N_LANGUAGE");
  useEffect(() => {
    const pathArr = pathname.split("/");
    const listLanguages = Object.keys(languages);
    if (listLanguages.includes(pathArr[1])) {
      setSelectedLang(pathArr[1]);
    } else {
      setSelectedLang(currentLanguage);
    }
  }, [pathname, currentLanguage]);

  const changeLanguageAction = (lang) => {
    //set language as i18n
    i18n.changeLanguage(lang);
    localStorage.setItem("I18N_LANGUAGE", lang);
    setSelectedLang(lang);

    moment.locale(lang === "en" ? "en-nz" : "vi");
  };

  const [isLanguageDropdown, setIsLanguageDropdown] = useState(false);
  const toggleLanguageDropdown = () => {
    setIsLanguageDropdown(!isLanguageDropdown);
  };
  return (
    <React.Fragment>
      <Dropdown
        isOpen={isLanguageDropdown}
        toggle={toggleLanguageDropdown}
        className={`${isWebsite ? "" : "ms-1 header-item"} topbar-head-dropdown`}
      >
        <DropdownToggle className="btn btn-icon btn-topbar btn-ghost-warning rounded-circle" tag="button">
          <img src={get(languages, `${selectedLang}.flag`)} alt="Header Language" height="20" className="rounded" />
        </DropdownToggle>
        <DropdownMenu className="notify-item language py-2">
          {Object.keys(languages).map((key) => (
            <DropdownItem
              key={key}
              onClick={() => changeLanguageAction(key)}
              className={`notify-item ${selectedLang === key ? "active" : "none"}`}
            >
              <Link to={`/${key}`}>
                <img src={get(languages, `${key}.flag`)} alt="Skote" className="me-2 rounded" height="18" />
                <span className="align-middle">{get(languages, `${key}.label`)}</span>
              </Link>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default LanguageDropdown;
