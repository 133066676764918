import React, { useRef, useState } from "react";
import ButtonTheme from "../../../Components/Common/ButtonTheme";
import ModalContainer from "../../../Components/Common/ModalContainer";
import { FieldArray, Formik, Form } from "formik";
import { Label } from "reactstrap";
import InputTheme from "../../../Components/Common/InputTheme";
import { cloneObjectOrArray, convertArrToObject, objectToArray } from "../../../common/constants";
import { toast } from "react-toastify";
import { servicedApi } from "../../../helpers/resource_helper";

export default function ModalPlan({ toggle, open, onChangePlan, selectService, dataPlan = {} }) {
  const [loading, setLoading] = useState(false);
  const refForm = useRef();
  const id = Object.keys(dataPlan).length > 0 ? true : false;
  const formValidation = {
    initialValues: {
      name: dataPlan.name || "",
      code: dataPlan.code || "",
      sale_price: dataPlan.sale_price || "",
      price: dataPlan.price || "",
      features: objectToArray(dataPlan?.features || [], "Key", "Description"),
    },
    onSubmit: async (values, actions) => {
      setLoading(true);
      const cloneValues = cloneObjectOrArray(values);
      cloneValues.features = convertArrToObject(values.features, "Key", "Description");
      try {
        const cloneService = cloneObjectOrArray(selectService);
        if (!cloneService.plans) {
          cloneService.plans = [];
        }
        if (id) {
          cloneService.plans = cloneService.plans.map((item) => {
            if (item.code === dataPlan?.code) return cloneValues;
            return item;
          });
        } else {
          cloneService.plans.push(cloneValues);
        }
        console.log("ttttt", cloneService);
        const res = await servicedApi.put(cloneService);
        onChangePlan({ ...res, id: selectService.id });
        actions.resetForm();
        toggle();
      } catch (error) {
        console.log("error", error);
        toast.error("Error!");
      }
      setLoading(false);
    },
  };

  return (
    <ModalContainer
      isOpen={open}
      title={id ? "Edit Plan" : "Add Plan"}
      toggle={toggle}
      actionCustom={
        <div className="hstack gap-2 justify-content-end mt-3">
          <ButtonTheme
            className="btn btn-warning w-sm"
            loading={loading}
            onClick={() => refForm.current.handleSubmit()}
          >
            {id ? "Edit" : "Create"}
          </ButtonTheme>
          <ButtonTheme
            type="button"
            className="btn btn-danger w-sm mx-2"
            onClick={() => {
              toggle();
            }}
          >
            Close
          </ButtonTheme>
        </div>
      }
    >
      <Formik
        enableReinitialize={true}
        initialValues={formValidation.initialValues}
        onSubmit={formValidation.onSubmit}
        innerRef={refForm}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <InputTheme name="name" label={"Name"} />
            <InputTheme name="code" label={"Code"} />
            <InputTheme name="price" label={"Price"} format="price" />
            <InputTheme name="sale_price" label={"Sale Price"} format="price" />
            <Label>Features</Label>
            <FieldArray name="features">
              {({ push, remove }) => (
                <div>
                  {values.features.map((item, index) => (
                    <div key={index} className="box-setting p-1">
                      <div className="align-items-center d-flex">
                        <InputTheme name={`features.${index}.Key`} label={"Key"} classWrapper="flex-1" />
                        <InputTheme
                          name={`features.${index}.Description`}
                          label={"Description"}
                          classWrapper="flex-1"
                        />
                        <div>
                          <button
                            className="btn btn-danger mx-3 p-1 hover-border-box mt-2"
                            type="button"
                            onClick={() => remove(index)}
                            style={{
                              height: "30px",
                            }}
                          >
                            <i className="bx bx-minus fs-22"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                  <button
                    className="btn btn-info btn-add p-1"
                    type="button"
                    onClick={() => push({})}
                    style={{
                      height: "32px",
                      width: "38px",
                    }}
                  >
                    <i className="bx bx-plus-medical fs-21"></i>
                  </button>
                </div>
              )}
            </FieldArray>
          </Form>
        )}
      </Formik>
    </ModalContainer>
  );
}
