import {
  ADD_SUBSCRIPTION,
  ADD_SUBSCRIPTION_FAIL,
  ADD_SUBSCRIPTION_SUCCESS,
  GET_SUBSCRIPTION,
  GET_SUBSCRIPTION_FAIL,
  GET_SUBSCRIPTION_SUCCESS,
  LIST_SUBSCRIPTION,
  LIST_SUBSCRIPTION_FAIL,
  LIST_SUBSCRIPTION_SUCCESS,
  PAUSE_SUBSCRIPTION,
  PAUSE_SUBSCRIPTION_FAIL,
  PAUSE_SUBSCRIPTION_SUCCESS,
  RESET_SUBSCRIPTION,
  RESUME_SUBSCRIPTION,
  RESUME_SUBSCRIPTION_FAIL,
  RESUME_SUBSCRIPTION_SUCCESS,
  SUSPEND_SUBSCRIPTION,
  SUSPEND_SUBSCRIPTION_FAIL,
  SUSPEND_SUBSCRIPTION_SUCCESS,
  UPDATE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION_FAIL,
  UPDATE_SUBSCRIPTION_SUCCESS,
} from "./actionTypes";

const INIT_STATE = {
  error: null,
  loading: false,
  subscriptions: [],
  subscription: {},
  success: false,
  limit: 10,
  page: 0,
  total: 0,
};

const Subscriptions = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_SUBSCRIPTION:
      return {
        ...state,
        loading: true,
      };
    case LIST_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        subscriptions: action.payload?.items || [],
        limit: action.payload.limit ?? 0,
        page: action.payload.page ?? 0,
        total: action.payload.total ?? 0,
      };

    case LIST_SUBSCRIPTION_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    case ADD_SUBSCRIPTION:
      return {
        ...state,
        error: null,
        success: false,
        loading: true,
      };

    case ADD_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        subscriptions: [action.payload, ...state.subscriptions],
        subscription: action.payload,
      };

    case ADD_SUBSCRIPTION_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    case GET_SUBSCRIPTION:
      return {
        ...state,
        error: null,
        success: false,
        loading: true,
      };

    case GET_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        subscription: action.payload,
      };

    case GET_SUBSCRIPTION_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    case UPDATE_SUBSCRIPTION:
      return {
        ...state,
        error: null,
        success: false,
        loading: true,
      };
    case UPDATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        subscriptions: state.subscriptions.map((subscription) =>
          subscription.id.toString() === action.payload.id.toString()
            ? { ...subscription, ...action.payload }
            : subscription,
        ),
        subscription: action.payload,
      };

    case UPDATE_SUBSCRIPTION_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case SUSPEND_SUBSCRIPTION:
      return {
        ...state,
        error: null,
        success: false,
        loading: true,
      };
    case SUSPEND_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        subscriptions: state.subscriptions.map((subscription) =>
          subscription.id.toString() === action.payload.id.toString()
            ? { ...subscription, ...action.payload }
            : subscription,
        ),
        subscription: action.payload,
      };
    case SUSPEND_SUBSCRIPTION_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case RESUME_SUBSCRIPTION:
      return {
        ...state,
        error: null,
        success: false,
        loading: true,
      };
    case RESUME_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        subscriptions: state.subscriptions.map((subscription) =>
          subscription.id.toString() === action.payload.id.toString()
            ? { ...subscription, ...action.payload }
            : subscription,
        ),
        subscription: action.payload,
      };
    case RESUME_SUBSCRIPTION_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case PAUSE_SUBSCRIPTION:
      return {
        ...state,
        error: null,
        success: false,
        loading: true,
      };
    case PAUSE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        subscriptions: state.subscriptions.map((subscription) =>
          subscription.id.toString() === action.payload.id.toString()
            ? { ...subscription, ...action.payload }
            : subscription,
        ),
        subscription: action.payload,
      };
    case PAUSE_SUBSCRIPTION_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case RESET_SUBSCRIPTION:
      return {
        ...state,
        subscription: {},
      };
    default:
      return { ...state };
  }
};

export default Subscriptions;
