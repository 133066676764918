import { servicedApi } from "../../helpers/resource_helper";
import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { listServiceFail, listServiceSuccess } from "./actions";
import { LIST_SERVICE,  } from "./actionTypes";


function* listServiceSaga({ payload }){
  try{
    const response = yield call(servicedApi.list,payload)
    yield put(listServiceSuccess(response))
  }catch (error){
    yield put(listServiceFail(error))
  }
}

export function* watchListService(){
  yield takeEvery(LIST_SERVICE,listServiceSaga)
}

function* serviceSaga(){
  yield all([fork(watchListService)])
}

export default serviceSaga;