import { Spinner } from "reactstrap";

const ButtonTheme = ({
  id,
  name,
  type,
  style,
  className,
  onClick = () => {},
  loading = false,
  disabled,
  children,
  refButton,
  ...field
}) => {
  return (
    <button
      id={id}
      name={name}
      disabled={disabled}
      className={className}
      type={type}
      onClick={onClick}
      style={style}
      ref={refButton}
      {...field}
    >
      {loading ? <Spinner size="sm" className={`align-middle ${children && "me-2"}`} /> : null}
      {children}
    </button>
  );
};

export default ButtonTheme;
