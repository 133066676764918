import { Actions } from "../../helpers/resource_helper";
import {
  LIST_SERVICE, LIST_SERVICE_ERROR,
  LIST_SERVICE_SUCCESS,
} from "./actionTypes";

const INIT_STATE = {
  error: null,
  loading: false,
  services: [],
  service: {},
  success: false,
  message: "",
  limit: 10,
  page: 0,
  total: 0,
  action: Actions.EMPTY,
};


const Service = (state = INIT_STATE,action) => {

  switch(action.type){
    case LIST_SERVICE:
      return {
        ...state,
        message: "List service Loading...",
        loading: false,
        action: Actions.LIST,
      };
    case LIST_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        services: action.payload?.items ,
        message: "List service success",
        success: true,
        limit: action.payload.limit ?? 0,
        page: action.payload.page ?? 0,
        total: action.payload.total ?? 0,
      };

    case LIST_SERVICE_ERROR:
      return {
        ...state,
        loading: false,
        message: "List service error...",
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };
    default:
      return { ...state };
  }
}

export default Service;