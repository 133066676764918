import { LIST_USER, LIST_USER_ERROR, LIST_USER_SUCCESS } from "./actionTypes";

const INIT_STATE = {
  error: null,
  loadingUser: false,
  users: [],
  lastKey: "",
};

const User = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_USER:
      return {
        ...state,
        loadingUser: true,
      };
    case LIST_USER_SUCCESS:
      return {
        ...state,
        loadingUser: false,
        users: action.payload.data,
        lastKey: action.payload.nextKey,
      };
    case LIST_USER_ERROR:
      return {
        ...state,
        loadingUser: false,
      };

    default:
      return { ...state };
  }
};

export default User;
