export const LIST_SUBSCRIPTION = "LIST_SUBSCRIPTION";
export const LIST_SUBSCRIPTION_SUCCESS = "LIST_SUBSCRIPTION_SUCCESS";
export const LIST_SUBSCRIPTION_FAIL = "LIST_SUBSCRIPTION_FAIL";

export const GET_SUBSCRIPTION = "GET_SUBSCRIPTION";
export const GET_SUBSCRIPTION_SUCCESS = "GET_SUBSCRIPTION_SUCCESS";
export const GET_SUBSCRIPTION_FAIL = "GET_SUBSCRIPTION_FAIL";

export const ADD_SUBSCRIPTION = "ADD_SUBSCRIPTION";
export const ADD_SUBSCRIPTION_SUCCESS = "ADD_SUBSCRIPTION_SUCCESS";
export const ADD_SUBSCRIPTION_FAIL = "ADD_SUBSCRIPTION_FAIL";

export const UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION";
export const UPDATE_SUBSCRIPTION_SUCCESS = "UPDATE_SUBSCRIPTION_SUCCESS";
export const UPDATE_SUBSCRIPTION_FAIL = "UPDATE_SUBSCRIPTION_FAIL";

export const SUSPEND_SUBSCRIPTION = "SUSPEND_SUBSCRIPTION";
export const SUSPEND_SUBSCRIPTION_SUCCESS = "SUSPEND_SUBSCRIPTION_SUCCESS";
export const SUSPEND_SUBSCRIPTION_FAIL = "SUSPEND_SUBSCRIPTION_FAIL";

export const RESUME_SUBSCRIPTION = "RESUME_SUBSCRIPTION";
export const RESUME_SUBSCRIPTION_SUCCESS = "RESUME_SUBSCRIPTION_SUCCESS";
export const RESUME_SUBSCRIPTION_FAIL = "RESUME_SUBSCRIPTION_FAIL";

export const PAUSE_SUBSCRIPTION = "PAUSE_SUBSCRIPTION";
export const PAUSE_SUBSCRIPTION_SUCCESS = "PAUSE_SUBSCRIPTION_SUCCESS";
export const PAUSE_SUBSCRIPTION_FAIL = "PAUSE_SUBSCRIPTION_FAIL";

export const RESET_SUBSCRIPTION = "RESET_SUBSCRIPTION";
