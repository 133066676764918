import { LIST_USER, LIST_USER_ERROR, LIST_USER_SUCCESS } from "./actionTypes";

export const getListUser = (data) => ({
  type: LIST_USER,
  payload: data,
});

export const listUserSuccess = (data, nextKey) => ({
  type: LIST_USER_SUCCESS,
  payload: {
    data,
    nextKey,
  },
});

export const listUserError = () => ({
  type: LIST_USER_ERROR,
});
