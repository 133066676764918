import React, { useEffect, useState } from "react";
import ContentPageTheme from "../../Components/Common/ContentPageTheme";
import { Table } from "antd";
import { servicedApi } from "../../helpers/resource_helper";
import { formatDate } from "../../helpers/format_helper";
import ButtonTheme from "../../Components/Common/ButtonTheme";
import ModalService from "./Components/ModalService";
import ModalPlan from "./Components/ModalPlan";
import StatusModal from "../../Components/Common/StatusModal";
import { cloneObjectOrArray, formatCurrency } from "../../common/constants";
import { toast } from "react-toastify";
import ModalSubscription from "./Components/ModalSubscription";
import SimpleBar from "simplebar-react";
import TableContainerCustom from "../../Components/Common/TableContainerCustom";
import { listSubscription } from "../../store/subscription/actions";
import { withTranslation } from "react-i18next";
import useMemoizedSelector from "../../Components/Hooks/useMemoizedSelector";
import { useDispatch } from "react-redux";
import { listService } from "../../store/service/actions";

function Services({ t }) {
  const [service, setService] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openModalService, setOpenModalService] = useState(false);
  const [openModalPlan, setOpenModalPlan] = useState(false);
  const [openModalDeletePlan, setOpenModalDeletePlan] = useState(false);
  const [openModalDeleteService, setOpenModalDeleteService] = useState(false);
  const [openModalSubscription, setOpenModalSubscription] = useState(false);
  const [selectService, setSelectService] = useState({});
  const [selectPlan, setSelectPlan] = useState({});
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const dispatch = useDispatch();

  const getService = async (dataPayload) => {
    setLoading(true);
    const res = await servicedApi.list(dataPayload ? dataPayload : { limit: 10 });
    setLoading(false);
    setService(res.items);
    setLimit(res.limit);
    setPage(res.page);
    setTotal(res.total);
  };
  useEffect(() => {
    getService();
  }, []);

  const {
    serviceData,
    serviceLoading,
    serviceAction,
    serviceLimit,
    servicePage,
    serviceTotal,
    serviceMessage,
    serviceSuccess,
    serviceError,
  } = useMemoizedSelector((state) => ({
    serviceData: state.Services.services,
    serviceLoading: state.Services.loading,
    serviceAction: state.Services.action,
    serviceLimit: state.Services.limit,
    servicePage: state.Services.page,
    serviceTotal: state.Services.total,
    serviceMessage: state.Services.loading,
    serviceSuccess: state.Services.success,
    serviceError: state.Services.error,
  }));

  const headers = ["name", "code", "description", "features", "created_at", "updated_at", "action"];
  useEffect(() => {
    dispatch(listService({ limit: 10 }));
  }, [dispatch, serviceLoading]);

  const handleChangeService = (dataService, id = "") => {
    setService((data) => {
      if (id)
        return data.map((item) => {
          if (item.id === id) return dataService;
          return item;
        });
      else return [...data, dataService];
    });
  };
  const handleChangePlan = (newService) => {
    setService((data) => {
      return data.map((item) => {
        if (item.id === newService.id) return newService;
        return item;
      });
    });
  };
  const handleActionChangePlan = (type, service, dataPlan) => {
    console.log("ok");
    setSelectPlan(dataPlan);
    setSelectService(service);
    if (type === "edit") {
      setOpenModalPlan(true);
    } else if (type === "delete") {
      setOpenModalDeletePlan(true);
    } else {
      setOpenModalSubscription(true);
    }
  };
  const handleClickDelete = async () => {
    setLoading(true);
    try {
      const newService = cloneObjectOrArray(selectService);
      newService.plans = newService.plans.filter((p) => p.code !== selectPlan.code);
      const res = await servicedApi.put(newService);
      handleChangeService({ ...res, id: newService.id }, newService.id);
      setOpenModalDeletePlan(false);
    } catch (error) {
      toast.error("Error!");
    }
    setLoading(false);
  };

  const handleClickDeleteService = async () => {
    setLoading(true);
    try {
      await servicedApi.delete({ id: selectService.id });
      setOpenModalDeleteService(false);
      toast.success("Delete success!");
      getService();
    } catch (error) {
      toast.error("Error!");
    }
    setLoading(false);
  };

  return (
    <ContentPageTheme title="List Service" pageTitle="Service">
      <SimpleBar
        className="file-manager-content w-100 p-3"
        style={{
          height: "calc(100vh - 226px)",
        }}
      >
        <div className="text-end my-3">
          <ButtonTheme
            className="btn btn-warning"
            onClick={() => {
              setOpenModalService(true);
              setSelectService({});
            }}
          >
            {"Add Service"}
          </ButtonTheme>
        </div>
        <TableContainerCustom
          filterType={"services"}
          tableView={true}
          searchPlaceHolder={"Search..."}
          // listFilter={[
          //   {
          //     id: "category.id",
          //     type: "selectBox",
          //     title: "Category",
          //     remote: true,
          //     pathUrlLoad: "product/categories",
          //   },
          //   {
          //     id: "brand.id",
          //     type: "selectBox",
          //     title: "Brand",
          //     remote: true,
          //     pathUrlLoad: "product/brands",
          //   },
          // ]}
          headers={headers}
          contents={serviceData}
          limitPage={Number(limit)}
          currentPage={Number(page) + 1}
          totalItems={total}
          showPagination={true}
          scrollHeight="50vh"
          onCallData={(dataPayload) => {
            dispatch(listService(dataPayload));
          }}
          loading={loading}
          isSettings={true}
          renderExpandedComponent={(data) => (
            <ExpandedRowRender data={data} onClickActionPlan={handleActionChangePlan} />
          )}
          listHeader={[
            {
              title: t("Product details"),
              key: "detail-product",
              children: [
                {
                  title: t("Name"),
                  dataIndex: "name",
                  key: "name",
                  sorter: true,
                  render: (value, row, index) => {
                    return (
                      <div className={"team-profile-img d-flex align-items-center "}>
                        <div className="team-content ">
                          <h5 className="fs-16 mb-1">{row?.name}</h5>
                          <p className="text-muted mb-0">{row?.domain}</p>
                        </div>
                      </div>
                    );
                  },
                },
                {
                  title: t("Code"),
                  dataIndex: "code",
                  key: "code",
                  sorter: true,
                  render: (value, row, index) => {
                    return (
                      <h5 className="mb-1 d-flex">
                        <strong>{value}</strong>
                      </h5>
                    );
                  },
                },
                {
                  title: "Description",
                  dataIndex: "description",
                  key: "description",
                  render: (value, row, index) => {
                    return (
                      <h5 className="mb-1 d-flex">
                        <strong>{value}</strong>
                      </h5>
                    );
                  },
                },
                {
                  title: "Created At",
                  dataIndex: "created_at",
                  key: "created_at",
                  render: (text) => formatDate(text),
                  sorter: true,
                },
                {
                  title: "Updated At",
                  dataIndex: "updated_at",
                  key: "updated_at",
                  render: (text) => formatDate(text),
                  sorter: true,
                },
                {
                  title: "Features",
                  dataIndex: "features",
                  key: "features",
                  render: (value, row, index) => {
                    const arrValue = Object.keys(value).map((item) => value[item]);
                    return arrValue.join(",");
                  },
                },
                {
                  title: t("Action"),
                  dataIndex: "action",
                  key: "action",
                  render: (value, row, index) => {
                    return (
                      <div>
                        <ButtonTheme
                          className={"btn btn-light btn-icon rounded-circle btn-sm"}
                          onClick={() => {
                            setOpenModalService(true);
                            setSelectService(row);
                          }}
                        >
                          <i className="ri-edit-2-fill"></i>
                        </ButtonTheme>
                        <ButtonTheme
                          className={"btn btn-success btn-icon rounded-circle btn-sm mx-3"}
                          onClick={async () => {
                            setOpenModalPlan(true);
                            setSelectService(row);
                            setSelectPlan({});
                          }}
                        >
                          <i className="ri-play-list-add-line"></i>
                        </ButtonTheme>
                        <ButtonTheme
                          className={"btn btn-danger btn-icon rounded-circle btn-sm"}
                          onClick={() => {
                            setOpenModalDeleteService(true);
                            setSelectService(row);
                          }}
                        >
                          <i className="ri-delete-bin-2-fill"></i>
                        </ButtonTheme>
                      </div>
                    );
                  },
                },
              ],
            },
          ]}
        />
      </SimpleBar>

      <ModalService
        open={openModalService}
        toggle={() => setOpenModalService(false)}
        onChangeService={handleChangeService}
        dataService={selectService}
      />
      <ModalPlan
        open={openModalPlan}
        toggle={() => setOpenModalPlan(false)}
        onChangePlan={handleChangePlan}
        selectService={selectService}
        dataPlan={selectPlan}
      />
      <StatusModal
        show={openModalDeletePlan}
        onClick={handleClickDelete}
        onCloseClick={() => setOpenModalDeletePlan(false)}
        loading={loading}
      />
      <StatusModal
        show={openModalDeleteService}
        onClick={handleClickDeleteService}
        onCloseClick={() => setOpenModalDeleteService(false)}
        loading={loading}
      />
      <ModalSubscription
        open={openModalSubscription}
        toggle={() => setOpenModalSubscription(false)}
        selectService={selectService}
        selectPlan={selectPlan}
      />
    </ContentPageTheme>
  );
}
export default withTranslation()(Services);
const ExpandedRowRender = ({ data, onClickActionPlan }) => {
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      },
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      sorter: (a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      },
    },
    {
      title: "Features",
      key: "features",
      dataIndex: "features",
      render: (features) => {
        const arrValue = Object.keys(features).map((item) => features[item]);
        return arrValue.join(",");
      },
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (p) => (
        <>
          {formatCurrency(p)}
          <sup>đ</sup>
        </>
      ),
      sorter: (a, b) => a.price - b.price,
    },
    {
      title: "Sale Price",
      dataIndex: "sale_price",
      key: "sale_price",
      render: (p) => (
        <>
          {formatCurrency(p)}
          <sup>đ</sup>
        </>
      ),
      sorter: (a, b) => a.sale_price - b.sale_price,
    },
    {
      title: "Action",
      key: "operation",
      render: (record) => (
        <div>
          <ButtonTheme
            className={"btn btn-light btn-icon rounded-circle btn-sm"}
            onClick={() => {
              onClickActionPlan("edit", data, record);
            }}
          >
            <i className="ri-edit-2-fill"></i>
          </ButtonTheme>

          <ButtonTheme
            className={"btn btn-success btn-icon rounded-circle btn-sm mx-2"}
            onClick={() => {
              onClickActionPlan("add", data, record);
            }}
          >
            <i className="bx bxs-cart-add"></i>
          </ButtonTheme>
          <ButtonTheme
            className={"btn btn-danger btn-icon rounded-circle btn-sm"}
            onClick={() => {
              onClickActionPlan("delete", data, record);
            }}
          >
            <i className="ri-delete-bin-2-fill"></i>
          </ButtonTheme>
        </div>
      ),
    },
  ];

  return <Table columns={columns} dataSource={data?.plans || []} pagination={false} rowKey={(record) => record.code} />;
};
