import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert } from "reactstrap";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import withRouter from "../../Components/Common/withRouter";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import ButtonTheme from "../../Components/Common/ButtonTheme";
import { useProfile } from "../../Components/Hooks/UserHooks";
import onexapisLogo from "../../assets/images/onexapis-logo.png";
import useMemoizedSelector from "../../Components/Hooks/useMemoizedSelector";

// actions
import { loginUser, socialLogin, resetLoginFlag } from "../../store/actions";

const Login = (props) => {
  const dispatch = useDispatch();

  const { user, errorMsg, loading, error } = useMemoizedSelector((state) => ({
    user: state.Account.user,
    errorMsg: state.Login.errorMsg,
    loading: state.Login.loading,
    error: state.Login.error,
  }));

  const [userLogin, setUserLogin] = useState([]);
  const [passwordShow, setPasswordShow] = useState(false);

  useEffect(() => {
    if (user && user) {
      const updatedUserData =
        process.env.REACT_APP_DEFAULTAUTH === "firebase" ? user.multiFactor.user.username : user.user.username;
      setUserLogin({
        username: updatedUserData,
        password: user.user.confirm_password ? user.user.confirm_password : "",
      });
    }
  }, [user]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: userLogin.username || "",
      password: userLogin.password || "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required(props.t("Please Enter Your Username")),
      password: Yup.string().required(props.t("Please Enter Your Password")),
    }),
    onSubmit: (values) => {
      dispatch(loginUser(values, props.router.navigate));
    },
  });

  const signIn = (type) => {
    dispatch(socialLogin(type, props.router.navigate));
  };

  //for facebook and google authentication
  const socialResponse = (type) => {
    signIn(type);
  };

  useEffect(() => {
    if (!loading && !!error) {
      let resetMsgTime = setTimeout(() => {
        dispatch(resetLoginFlag());
      }, 3000);
      return () => clearTimeout(resetMsgTime);
    }
  }, [dispatch, error, loading]);

  document.title = `${props.t("Basic Sign In")} | OneXAPIs`;
  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <Link to="/" className="d-inline-block auth-logo">
                      <img src={onexapisLogo} alt="" height="100" />
                    </Link>
                  </div>
                  <p className="mt-3 fs-15 fw-medium">All-in-One Automation and Integration Solution</p>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-warning">{props.t("Welcome Back!")}</h5>
                      <p className="text-muted">{props.t("Sign in to continue to OneXAPIs.")}</p>
                    </div>
                    {errorMsg && errorMsg ? <Alert color="danger"> {props.t(errorMsg)} </Alert> : null}
                    <div className="p-2 mt-4">
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                        action="#"
                      >
                        <div className="mb-3">
                          <Label htmlFor="username" className="form-label">
                            {props.t("Username")}
                          </Label>
                          <Input
                            name="username"
                            className="form-control"
                            placeholder={props.t("Enter username")}
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.username || ""}
                            invalid={validation.touched.username && validation.errors.username ? true : false}
                            autoComplete="off"
                          />
                          {validation.touched.username && validation.errors.username ? (
                            <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label" htmlFor="password-input">
                            {props.t("Password")}
                          </Label>
                          <div className="position-relative auth-pass-inputgroup mb-3">
                            <Input
                              name="password"
                              value={validation.values.password || ""}
                              type={passwordShow ? "text" : "password"}
                              className="form-control pe-5"
                              placeholder={props.t("Enter Password")}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={validation.touched.password && validation.errors.password ? true : false}
                            />
                            {validation.touched.password && validation.errors.password ? (
                              <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                            ) : null}
                            <button
                              className={`btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted ${
                                validation.touched.password && validation.errors.password ? "me-4" : ""
                              }`}
                              type="button"
                              id="password-addon"
                              onClick={() => setPasswordShow(!passwordShow)}
                            >
                              {" "}
                              <i className={`${passwordShow ? "ri-eye-off-fill" : "ri-eye-fill"} align-middle`}></i>{" "}
                            </button>
                          </div>
                        </div>

                        <div className="form-check">
                          <Input className="form-check-input" type="checkbox" value="" id="auth-remember-check" />
                          <Label className="form-check-label" htmlFor="auth-remember-check">
                            {props.t("Remember me")}
                          </Label>
                          <div className="float-end">
                            <Link to="/forgot-password" className="text-muted">
                              {props.t("Forgot password?")}
                            </Link>
                          </div>
                        </div>

                        <div className="mt-4">
                          <ButtonTheme
                            color="success"
                            className="btn btn-warning w-100"
                            type="submit"
                            loading={loading}
                          >
                            Sign In
                          </ButtonTheme>
                        </div>

                        <div className="mt-4 text-center">
                          <div className="signin-other-title">
                            <h5 className="fs-13 mb-4 title">{props.t("Sign In with")}</h5>
                          </div>
                          <div>
                            <button type="button" className="btn btn-primary btn-icon waves-effect waves-light">
                              <i className="ri-facebook-fill fs-16"></i>
                            </button>{" "}
                            <button type="button" className="btn btn-danger btn-icon waves-effect waves-light">
                              <i className="ri-google-fill fs-16"></i>
                            </button>{" "}
                            <button type="button" className="btn btn-dark btn-icon waves-effect waves-light">
                              <i className="ri-github-fill fs-16"></i>
                            </button>{" "}
                            <button type="button" className="btn btn-info btn-icon waves-effect waves-light">
                              <i className="ri-twitter-fill fs-16"></i>
                            </button>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>

                {/* <div className="mt-4 text-center">
                  <p className="mb-0">
                    {props.t("Don't have an account?")}{" "}
                    <Link to="/register" className="fw-semibold text-warning text-decoration-underline">
                      {" "}
                      {props.t("Sign up")}{" "}
                    </Link>{" "}
                  </p>
                </div> */}
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default withRouter(withTranslation()(Login));
