import { apiCli } from "../api_helper";

export const signInAPI = (data) => apiCli.create("auth/login", data);

export const signUpAPI = (data) => apiCli.create("auth/register", data);

export const getInfoAPI = (data) => apiCli.get(`user_info${data ? `?access_token=${data}` : ""}`); /*  */

export const changePasswordAPI = (data) => apiCli.create("auth/change_password", data);

export const confirmCodeAPI = (data) => apiCli.create("auth/confirm", data);

export const resendCodeAPI = (data) => apiCli.create("auth/resend_code", data);

export const forgotPasswordAPI = (data) => apiCli.create("auth/forgot", data);

export const resetPasswordAPI = (data) => apiCli.create("auth/new_password", data);

export const refreshTokenAPI = (data) => apiCli.create("auth/refresh", data);
