import { TreeSelect } from "antd";
import { ErrorMessage, useField } from "formik";
import { useTranslation, withTranslation } from "react-i18next";
import Select from "react-select";
import { FormFeedback, FormGroup, Label } from "reactstrap";
import PropTypes from "prop-types";
import { components } from "react-select";
import AsyncSelect from "react-select/async";
import { getListDataDefault } from "../../helpers/service_helper";
import { Fragment, useEffect, useState } from "react";
import { useDebounceFunction } from "../Hooks/UseDebounce";

const { Option } = components;
function SelectTheme({ isForm = true, ...props }) {
  if (isForm) return <SelectFormField {...props} />;
  return <SelectField {...props} />;
}

const SelectFormField = (props) => {
  const {
    name = "",
    options = [],
    label,
    placeholder,
    disabled,
    typeSelect = "select",
    defaultValue,
    classWrapper,
    t,
    remote = false,
    path = "",
    optionSearch,
    keyLabel = "",
    isObjectValueSelect = false,
    selectDataOrigin = false,
    onChange = () => {},
    extraOptions = [],
  } = props;
  const [field, meta, helpers] = useField(name);
  let showError = meta.error && meta.touched;
  const newOptions = options.map((item) => {
    const { id, name, ...res } = item;
    return { value: id, label: name, ...res };
  });
  let selectedOption = field.value
    ? remote
      ? field.value
      : newOptions.find(
          (option) =>
            option.value === field.value || option.value === field?.value?.value || option.value === field?.value?.id,
        )
    : "";
  const customStyles = {
    // option: (base, { data, isDisabled, isFocused, isSelected }) => {
    //   return {
    //     ...base,
    //     backgroundColor: isSelected ? "#f7b84b" : "#fdc05559",
    //   };
    // },
    menu: (base, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...base,
        background: "#fff",
        zIndex: 9999, // Đảm bảo menu hiển thị trên cùng
      };
    },
    control: (base, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...base,
        borderWidth: "1px",
        borderStyle: "solid",
        borderRadius: "4px",
        backgroundColor: "#fff",
        borderColor: `${showError ? "#f06548" : isFocused ? "var(--vz-input-focus-border)" : "var(--vz-input-border)"}`,
        boxShadow: "none",
        ...(isFocused && {
          "&:hover": {
            borderColor: "var(--vz-input-focus-border)",
          },
        }),
      };
    },
  };
  const [firstRun, setFirstRun] = useState(true);
  const [dataSelect, setDataSelect] = useState({});
  const [listOriginalData, setListOriginData] = useState([]);

  const handleSelectedOptionChange = (selectedOption) => {
    if (typeSelect === "tree") {
      helpers.setValue(selectedOption);
    } else {
      const selectedValue = selectedOption
        ? isObjectValueSelect
          ? selectDataOrigin
            ? newOptions.find((item) => item.value === selectedOption.value)
            : selectedOption
          : selectedOption.value
        : selectedOption;
      helpers.setValue(selectedValue);
    }

    onChange(selectedOption);
  };

  // Hàm debounce để trì hoãn việc gọi API
  const debounceCallAPI = useDebounceFunction(getListDataDefault, 800);

  const promiseOptions = async (inputValue, callback) => {
    try {
      const res = await debounceCallAPI(path, {
        ...optionSearch,
        ...(inputValue && { query: inputValue?.trim() }),
        limit: 10,
      });

      const newListOriginData = res.items.map((item) => {
        const { updated_at, created_at, company_id, ...obj } = item;
        return obj;
      });
      setListOriginData(newListOriginData);

      const mapOption = res.items.map((item) => ({
        data: item,
        value: item.id,
        label: keyLabel
          ? Array.isArray(keyLabel)
            ? `${item[keyLabel[0]]} ${item[keyLabel[1]]}`
            : item[keyLabel]
          : item.name,
      }));
      let selectData = mapOption.find(
        (item) => item.value === field.value || item.value === field?.value?.value || item.value === field?.value?.id,
      );
      // if (!selectData && field.value) {
      //   let objNew = {};
      //   if (field.value?.id) {
      //     objNew = { value: field.value?.id, label: field.value?.name };
      //   } else {
      //     console.log(field);
      //     const resValue = await getListDataDefault(path, {
      //       query: field?.value?.value ? field.value?.label : field.value.name,
      //     });
      //     console.log(resValue);
      //     objNew = { value: resValue.items[0].id, label: resValue.items[0].name };
      //   }
      //   mapOption.unshift(objNew);
      //   selectData = objNew;
      //   setDataSelect(selectData);
      // } else {
      //   setDataSelect(selectData);
      // }
      setDataSelect(selectData);

      return mapOption;
    } catch (e) {
      console.error(e);
    }
  };

  const dataAsyncSelect = listOriginalData
    .map((item) => ({
      value: item.id,
      label: keyLabel
        ? Array.isArray(keyLabel)
          ? `${item[keyLabel[0]]} ${item[keyLabel[1]]}`
          : item[keyLabel]
        : item.name,
    }))
    ?.find(
      (option) =>
        option.value === field.value || option.value === field?.value?.value || option.value === field?.value?.id,
    );
  useEffect(() => {
    setDataSelect(dataAsyncSelect);
    if (firstRun) {
      setFirstRun(false);
      return;
    }
    if (!dataAsyncSelect) setForceReload((prevForceReload) => !prevForceReload);
  }, [field.value]);

  const [forceReload, setForceReload] = useState(false);
  return (
    <FormGroup className={`${classWrapper ? classWrapper : ""} ${showError ? "is-invalid" : ""}`}>
      {label && (
        <Label for={name} className="w-100">
          {typeof label === "string" ? t(label) : label}
        </Label>
      )}
      <div
      // style={{
      //   border: `${showError ? "1px solid red" : null}`,
      //   borderRadius: "4px",
      // }}
      >
        {typeSelect === "tree" ? (
          <TreeSelect
            showSearch
            size="large"
            style={{
              width: "100%",
              height: "38px",
              borderRadius: "4px",
            }}
            value={field.value}
            dropdownStyle={{
              maxHeight: 400,
              minWidth: 200,
              overflow: "auto",
            }}
            treeLine={true}
            placeholder={t("Please select")}
            allowClear
            treeDefaultExpandAll
            onChange={handleSelectedOptionChange}
            treeData={newOptions}
          />
        ) : remote ? (
          <>
            <AsyncSelect
              key={forceReload}
              cacheOptions
              defaultOptions
              value={dataSelect}
              loadOptions={promiseOptions}
              components={{ Option: IconOption }}
              styles={customStyles}
              className={`${showError ? "is-invalid-select" : ""}`}
              onChange={(option) => {
                helpers.setValue(
                  isObjectValueSelect
                    ? selectDataOrigin
                      ? listOriginalData.find((item) => item.id === option.value)
                      : option
                    : option.value,
                );
                onChange(option);
              }}
              placeholder={t(placeholder)}
              isDisabled={disabled}
              isInvalid={showError}
            />
          </>
        ) : (
          <Select
            {...field}
            value={selectedOption}
            onChange={handleSelectedOptionChange}
            placeholder={t(placeholder)}
            isDisabled={disabled}
            options={newOptions}
            styles={customStyles}
            classNamePrefix="select"
            className={`basic-single ${showError ? "is-invalid" : ""}`}
            defaultValue={defaultValue}
            components={{ Option: IconOption }}
            menuPosition="fixed"
            error={meta.state}
            touched={meta.state}
            // menuPortalTarget={document.body} // Gắn menu vào body
            menuShouldScrollIntoView={false}
          />
        )}
      </div>
      {name && <ErrorMessage name={name} component={FormFeedback} className="position-absolute fs-10 m-0" />}
    </FormGroup>
  );
};

const SelectField = ({
  options = [],
  placeholder,
  onChange,
  t,
  value = "",
  selectDataOrigin = true,
  classWrapper,
  defaultValue,
  disabled,
  style,
  label,
  remote,
  path,
  optionSearch,
  keyLabel = "",
  isObjectValueSelect = false,
}) => {
  const newOptions = options.map((item) => {
    const { id, name, ...res } = item;
    return { value: id, label: name, ...res };
  });
  const customStyles = {
    menu: (base, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...base,
        background: "#fff",
      };
    },
    control: (base, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...base,
        borderWidth: "1px",
        borderStyle: "solid",
        borderRadius: "4px",
        backgroundColor: "#fff",
        borderColor: `${isFocused ? "var(--vz-input-focus-border)" : "var(--vz-input-border)"}`,
        boxShadow: "none",
        ...(isFocused && {
          "&:hover": {
            borderColor: "var(--vz-input-focus-border)",
          },
        }),
      };
    },
  };
  let selectedOption = typeof value === "object" ? value : newOptions.find((option) => option.value === value);
  const handleChange = (data) => {
    selectDataOrigin ? onChange(data) : onChange(data.value);
  };
  const [firstRun, setFirstRun] = useState(true);
  const [dataSelect, setDataSelect] = useState({});
  const [forceReload, setForceReload] = useState(false);
  const [listOriginalData, setListOriginData] = useState([]);
  const debounceCallAPI = useDebounceFunction(getListDataDefault, 800);

  const dataAsyncSelect = listOriginalData
    .map((item) => ({
      value: item.id,
      label: keyLabel
        ? Array.isArray(keyLabel)
          ? `${item[keyLabel[0]]} ${item[keyLabel[1]]}`
          : item[keyLabel]
        : item.name,
    }))
    ?.find((option) => option.value === value || option.value === value?.value || option.value === value?.id);
  useEffect(() => {
    setDataSelect(dataAsyncSelect);
    if (firstRun) {
      setFirstRun(false);
      return;
    }
    if (!dataAsyncSelect) setForceReload((prevForceReload) => !prevForceReload);
  }, [value]);

  const promiseOptions = async (inputValue, callback) => {
    try {
      const res = await debounceCallAPI(path, {
        ...optionSearch,
        ...(inputValue && { query: inputValue?.trim() }),
        limit: 10,
      });
      const newListOriginData = res.items.map((item) => {
        const { updated_at, created_at, company_id, ...obj } = item;
        return obj;
      });
      setListOriginData(newListOriginData);
      const mapOption = res.items.map((item) => ({
        data: item,
        value: item.id,
        label: keyLabel
          ? Array.isArray(keyLabel)
            ? `${item[keyLabel[0]]} ${item[keyLabel[1]]}`
            : item[keyLabel]
          : item.name,
      }));
      let selectData = mapOption.find(
        (item) => item.value === value || item.value === value?.value || item.value === value?.id,
      );
      setDataSelect(selectData);

      return mapOption;
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <FormGroup className={`${classWrapper ? classWrapper : ""}`} style={style} noMargin={true}>
      {label && <Label className="w-100">{typeof label === "string" ? t(label) : label}</Label>}
      {remote ? (
        <AsyncSelect
          key={forceReload}
          cacheOptions
          defaultOptions
          value={dataSelect}
          loadOptions={promiseOptions}
          components={{ Option: IconOption }}
          styles={customStyles}
          // className={`${showError ? "is-invalid-select" : ""}`}
          onChange={(option) => {
            handleChange(
              isObjectValueSelect
                ? selectDataOrigin
                  ? listOriginalData.find((item) => item.id === option.value)
                  : option
                : option.value,
            );
          }}
          placeholder={t(placeholder)}
          isDisabled={disabled}
          // isInvalid={showError}
        />
      ) : (
        <Select
          placeholder={t(placeholder)}
          styles={customStyles}
          value={selectedOption}
          onChange={handleChange}
          options={newOptions}
          defaultValue={defaultValue}
          isDisabled={disabled}
          components={{ Option: IconOption }}
          menuPosition="fixed"
        />
      )}
    </FormGroup>
  );
};

const IconOption = (props) => {
  const { t } = useTranslation();
  return (
    <Option {...props}>
      <div className="d-flex align-items-center">
        {props.data.image && (
          <img
            src={props.data.image}
            alt="icon"
            style={{ width: "30px", height: "25px", objectFit: "contain", marginRight: "6px" }}
          />
        )}
        {t(props.data.label)}
      </div>
    </Option>
  );
};

SelectTheme.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  typeSelect: PropTypes.string,
  onchange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  classWrapper: PropTypes.string,
  isForm: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default withTranslation()(SelectTheme);
