import axios from "axios";
import { api } from "../config";
import { refreshTokenAPI } from "./AuthAPI";

// default
axios.defaults.baseURL = api.API_URL;
// content type
axios.defaults.headers.post["Content-Type"] = "application/json";

// content type
const getToken = () => {
  return JSON.parse(localStorage.getItem("authUser"))
    ? JSON.parse(localStorage.getItem("authUser"))?.Token.IdToken
    : null;
};

const getAccessToken = () => {
  return JSON.parse(localStorage.getItem("authUser"))
    ? JSON.parse(localStorage.getItem("authUser"))?.Token.AccessToken
    : null;
};

const getRefreshToken = () => {
  return JSON.parse(localStorage.getItem("authUser"))
    ? JSON.parse(localStorage.getItem("authUser"))?.Token.RefreshToken
    : null;
};

const getLoggedinUser = () => {
  const user = localStorage.getItem("authUser");
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};

class APIClient {
  /**
   * Fetches data from given url
   */

  //  get = (url, params) => {
  //   return axios.get(url, params);
  // };
  get = (url, params = {}) => {
    try {
      let response;

      let paramKeys = [];

      if (Object.keys(params).length > 0) {
        Object.keys(params).map((key) => {
          if (params[key]) {
            paramKeys.push(key + "=" + params[key]);
            return paramKeys;
          } else {
            return "";
          }
        });
        const queryString = paramKeys && paramKeys.length ? paramKeys.join("&") : "";
        response = axios.get(`${url}?${queryString}`, params);
      } else {
        response = axios.get(url);
      }

      return response;
    } catch (error) {
      console.error(error);
    }
  };
  /**
   * post given data to url
   */
  create = (url, data, config) => {
    return axios.post(url, data, config);
  };
  /**
   * Updates data
   */
  update = (url, data) => {
    return axios.patch(url, data);
  };

  put = (url, data) => {
    return axios.put(url, data);
  };
  /**
   * Delete
   */
  delete = (url, config) => {
    return axios.delete(url, { ...config });
  };
}

export const apiCli = new APIClient();

axios.interceptors.request.use(
  (config) => {
    let token = getToken();
    let accessToken = getAccessToken();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    if (accessToken) {
      config.params = {
        ...config.params,
        access_token: accessToken,
      };
    }
    if (config?.url === "auth/login") {
      if (config?.params?.access_token) {
        delete config?.params;
      }
    }
    // remove authorization when refresh token
    if (config.url === "auth/refresh") {
      delete config.params;
      delete config.headers["Authorization"];
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// resolve multi request Unauthorized
let isRefreshing = false;
let failedQueue = [];
function addFailedQueue(callback) {
  failedQueue.push(callback);
}
function callBackFailedRequests() {
  failedQueue = failedQueue.forEach((callback) => callback());
}

// intercepting to capture errors
axios.interceptors.response.use(
  function (response) {
    return response.data ? response.data : response;
  },
  async function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let originalRequest = error.config;
    let errorMessage = error?.response?.data?.message;
    switch (error.response.status) {
      case 500:
        if (!errorMessage) {
          errorMessage = "Internal Server Error";
        }
        break;
      case 401:
        if (!errorMessage) {
          errorMessage = "Invalid credentials";
        }
        // refresh token
        if (!originalRequest._retry && getToken() && error?.response?.data?.message === "Unauthorized") {
          const retryOriginalRequest = new Promise((resolve) => {
            addFailedQueue(() => {
              resolve(axios.request(originalRequest));
            });
          });
          if (!isRefreshing) {
            try {
              isRefreshing = true;
              let refreshToken = getRefreshToken();
              const res = await refreshTokenAPI({ refresh_token: refreshToken });
              let authUser = {
                ...JSON.parse(localStorage.getItem("authUser")),
              };
              let newToken = { ...authUser.Token, ...res };
              authUser.Token = newToken;
              localStorage.setItem("authUser", JSON.stringify(authUser));
              originalRequest.headers.Authorization = "Bearer " + getToken();
              callBackFailedRequests();
            } catch (err) {
              window.location.href = "/logout";
            } finally {
              isRefreshing = false;
              failedQueue = [];
            }
          }
          return retryOriginalRequest;
        }
        break;
      case 404:
        if (!errorMessage) {
          errorMessage = "Sorry! the data you are looking for could not be found";
        }
        break;
      default:
        errorMessage = error?.response?.data?.message || error;
    }
    return Promise.reject(errorMessage);
  },
);
/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
};

class Resource {
  constructor(resourceName) {
    this.resourceName = resourceName;
    this.api_client = new APIClient();
  }

  list = (params) => this.api_client.get(this.resourceName, params);

  get = ({ id: resourceId }) => this.api_client.get(`${this.resourceName}/${resourceId}`);

  filter = (params) => this.api_client.get(this.resourceName, params);

  create = (payload) => this.api_client.create(this.resourceName, payload);

  customCreate = (customPath, payload) => this.api_client.create(`${this.resourceName}/${customPath}`, payload);

  createCustomPath = (customPath, payload) => this.api_client.create(`${this.resourceName}/${customPath}`, payload);

  update = (payload) => this.api_client.update(`${this.resourceName}/${payload.id}`, payload);

  put = (payload) => this.api_client.put(`${this.resourceName}/${payload.id}`, payload);

  customPut = (customPath, payload) => this.api_client.put(`${this.resourceName}/${customPath}`, payload);

  delete = ({ id: resourceId }) => this.api_client.delete(`${this.resourceName}/${resourceId}`);

  customDelete = (customPath) => this.api_client.delete(`${this.resourceName}/${customPath}`);
}

export { APIClient, Resource, setAuthorization, getLoggedinUser };
