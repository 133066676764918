import { LIST_SUBSCRIPTION, LIST_SUBSCRIPTION_FAIL, LIST_SUBSCRIPTION_SUCCESS } from "../subscription/actionTypes";
import { LIST_SERVICE, LIST_SERVICE_ERROR, LIST_SERVICE_SUCCESS } from "./actionTypes";

export const listService = (payload) => ({
  type: LIST_SERVICE,
  payload,
});

export const listServiceSuccess = (data) => ({
  type: LIST_SERVICE_SUCCESS,
  payload: data,
});

export const listServiceFail = (error) => ({
  type: LIST_SERVICE_ERROR,
  payload: error,
})