import {
  ADD_SUBSCRIPTION,
  ADD_SUBSCRIPTION_FAIL,
  ADD_SUBSCRIPTION_SUCCESS,
  GET_SUBSCRIPTION,
  GET_SUBSCRIPTION_FAIL,
  GET_SUBSCRIPTION_SUCCESS,
  LIST_SUBSCRIPTION,
  LIST_SUBSCRIPTION_FAIL,
  LIST_SUBSCRIPTION_SUCCESS,
  PAUSE_SUBSCRIPTION,
  PAUSE_SUBSCRIPTION_FAIL,
  PAUSE_SUBSCRIPTION_SUCCESS,
  RESET_SUBSCRIPTION,
  RESUME_SUBSCRIPTION,
  RESUME_SUBSCRIPTION_FAIL,
  RESUME_SUBSCRIPTION_SUCCESS,
  SUSPEND_SUBSCRIPTION,
  SUSPEND_SUBSCRIPTION_FAIL,
  SUSPEND_SUBSCRIPTION_SUCCESS,
  UPDATE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION_FAIL,
  UPDATE_SUBSCRIPTION_SUCCESS,
} from "./actionTypes";

export const listSubscription = (payload) => ({
  type: LIST_SUBSCRIPTION,
  payload,
});

export const listSubscriptionSuccess = (data) => ({
  type: LIST_SUBSCRIPTION_SUCCESS,
  payload: data,
});

export const listSubscriptionFail = (error) => ({
  type: LIST_SUBSCRIPTION_FAIL,
  payload: error,
});

export const getSubscription = (payload) => ({
  type: GET_SUBSCRIPTION,
  payload,
});

export const getSubscriptionSuccess = (data) => ({
  type: GET_SUBSCRIPTION_SUCCESS,
  payload: data,
});

export const getSubscriptionFail = (error) => ({
  type: GET_SUBSCRIPTION_FAIL,
  payload: error,
});

export const addSubscription = (payload, handleAfterSubmit = () => {}) => ({
  type: ADD_SUBSCRIPTION,
  payload: { payload, handleAfterSubmit },
});

export const addSubscriptionSuccess = (data) => ({
  type: ADD_SUBSCRIPTION_SUCCESS,
  payload: data,
});

export const addSubscriptionFail = (error) => ({
  type: ADD_SUBSCRIPTION_FAIL,
  payload: error,
});

export const updateSubscription = (payload, handleAfterSubmit = () => {}) => ({
  type: UPDATE_SUBSCRIPTION,
  payload: { payload, handleAfterSubmit },
});

export const updateSubscriptionSuccess = (data) => ({
  type: UPDATE_SUBSCRIPTION_SUCCESS,
  payload: data,
});

export const updateSubscriptionFail = (error) => ({
  type: UPDATE_SUBSCRIPTION_FAIL,
  payload: error,
});

export const suspendSubscription = (payload) => ({
  type: SUSPEND_SUBSCRIPTION,
  payload,
});

export const suspendSubscriptionSuccess = (data) => ({
  type: SUSPEND_SUBSCRIPTION_SUCCESS,
  payload: data,
});

export const suspendSubscriptionFail = (error) => ({
  type: SUSPEND_SUBSCRIPTION_FAIL,
  payload: error,
});

export const resumeSubscription = (payload) => ({
  type: RESUME_SUBSCRIPTION,
  payload,
});

export const resumeSubscriptionSuccess = (data) => ({
  type: RESUME_SUBSCRIPTION_SUCCESS,
  payload: data,
});

export const resumeSubscriptionFail = (error) => ({
  type: RESUME_SUBSCRIPTION_FAIL,
  payload: error,
});

export const pauseSubscription = (payload) => ({
  type: PAUSE_SUBSCRIPTION,
  payload,
});

export const pauseSubscriptionSuccess = (data) => ({
  type: PAUSE_SUBSCRIPTION_SUCCESS,
  payload: data,
});

export const pauseSubscriptionFail = (error) => ({
  type: PAUSE_SUBSCRIPTION_FAIL,
  payload: error,
});

export const resetSubscription = () => ({
  type: RESET_SUBSCRIPTION,
});
