import { Table } from "antd";
import ContentPageTheme from "../../Components/Common/ContentPageTheme";
import { messagesApi } from "../../helpers/resource_helper";
import { useEffect, useState } from "react";
import ModalMessage from "./ModalMessage";
import ButtonTheme from "../../Components/Common/ButtonTheme";
import TableContainerCustom from "../../Components/Common/TableContainerCustom";
import SimpleBar from "simplebar-react";
import { formatDate } from "../../helpers/format_helper";
import { Card, CardBody } from "reactstrap";

export default function Message() {
  const [messages, setMessages] = useState([]);
  const [openModalMessage, setOpenModalMessage] = useState(false);
  const [selectMessage, setSelectMessage] = useState({});
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const getListMessage = async (dataPayload) => {
    try {
      setLoading(true);
      const res = await messagesApi.list(dataPayload ? dataPayload : { limit: 10 });
      setMessages(res.items);
      setLimit(res.limit);
      setPage(res.page);
      setTotal(res.total);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  useEffect(() => {
    getListMessage();
  }, []);
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: true,
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
    },
    {
      title: "Source",
      dataIndex: "source",
      key: "source",
      sorter: true,
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => formatDate(text),
      sorter: true,
      defaultSortOrder: "desc",
    },
    {
      title: "Action",
      key: "operation",
      render: (record) => (
        <div>
          <ButtonTheme
            className={"btn btn-light btn-icon rounded-circle btn-sm"}
            onClick={() => {
              setOpenModalMessage(true);
              setSelectMessage(record);
            }}
          >
            <i className="ri-eye-line"></i>
          </ButtonTheme>
          {/* <ButtonTheme className={"btn btn-success btn-icon rounded-circle btn-sm mx-3"} onClick={async () => {}}>
            <i className="ri-repeat-2-line"></i>
          </ButtonTheme> */}
        </div>
      ),
    },
  ];

  return (
    <div>
      <ModalMessage open={openModalMessage} toggle={() => setOpenModalMessage(false)} dataMessage={selectMessage} />
      <ContentPageTheme title="List Message" pageTitle="Message">
        <Card>
          <CardBody>
            <TableContainerCustom
              filterType={"message"}
              searchPlaceHolder="Search messages..."
              listFilter={[]}
              headers={columns}
              contents={messages}
              limitPage={Number(limit)}
              currentPage={Number(page) + 1}
              totalItems={total}
              loading={loading}
              scrollHeight="50vh"
              showPagination={true}
              tableView={true}
              onCallData={(dataPayload) => {
                getListMessage({ limit, page, ...dataPayload });
              }}
            />
          </CardBody>
        </Card>
      </ContentPageTheme>
    </div>
  );
}
