export const TYPE_NODE = {
  END: "endNode",
  START: "startNode",
  ACTION: "actionNode",
};

export const TYPE_ACTION_SERVER = {
  MAPPING: "MAPPING",
  CONNECTION: "CONNECTION",
  CONDITION: "CONDITION",
};

export const uuid = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
  );
};

const keyStartNode = uuid();
const keyEndNode = uuid();
export const initialNodes = [
  {
    id: keyStartNode,
    type: TYPE_NODE.START,
    data: {
      label: TYPE_NODE.START,
    },
    position: { x: -280, y: 0 },
  },
  {
    id: keyEndNode,
    type: TYPE_NODE.END,
    data: {
      label: TYPE_NODE.END,
    },
    position: { x: 280, y: 0 },
  },
];
export const initialEdges = [
  {
    id: uuid(),
    source: keyStartNode,
    target: keyEndNode,
    data: {
      source: keyStartNode,
    },
    markerEnd: {
      type: "arrow",
    },
    type: "buttonedge",
  },
];

export const KEY_CHANGE_TABLE = {
  NEXT: "next",
  PVE: "pve",
};

export const STATUS_NODE = {
  NONE_DATA: "NONE-DATA",
  NODE_DATA: "NODE-DATA",
  SUCCESS_NODE: "SUCCESS_NODE",
  ERROR_NODE: "ERROR_NODE",
};

export const KEY_CHANGE_URL = {
  LAZADA: "LAZADA",
};

export const keyExists = (obj, key) => {
  if (!obj || (typeof obj !== "object" && !Array.isArray(obj))) {
    return false;
  } else if (obj.hasOwnProperty.call(obj, key)) {
    return true;
  } else if (Array.isArray(obj)) {
    for (let i = 0; i < obj.length; i++) {
      const result = keyExists(obj[i], key);
      if (result) {
        return result;
      }
    }
  } else {
    for (const k in obj) {
      const result = keyExists(obj[k], key);
      if (result) {
        return result;
      }
    }
  }

  return false;
};

export function deepCopy(src, keyFind, obj) {
  let target = Array.isArray(src) ? [] : {};
  for (let key in src) {
    let v = src[key];
    if (v) {
      if (typeof v === "object") {
        target[key] = deepCopy(v, keyFind, obj);
      } else {
        if (keyFind === key) {
          console.log({ keyFind });
          target[key] = { v, ...obj };
          console.log("sdfsd", { v, ...obj });
        } else {
          target[key] = v;
        }
      }
    } else {
      if (keyFind === key) {
        console.log({ keyFind });
        target[key] = { ...v, ...obj };
      } else {
        target[key] = v;
      }
    }
  }

  return target;
}

export const SubscriptionOptions = [
  { id: "ACTIVE", name: "Active" },
  { id: "EXPIRED", name: "Expired" },
  { id: "PAUSED", name: "Paused" },
  { id: "SUSPENDED", name: "Suspended" },
];
