import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Empty } from "antd";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import ContentLoader from "react-content-loader";
import SimpleBar from "simplebar-react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import {
  Card,
  CardBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  UncontrolledDropdown,
} from "reactstrap";

import { changePasswordApi } from "../../helpers/resource_helper";

import ModalDetailUser from "./ModalDetailUser";
import ContentPageTheme from "../../Components/Common/ContentPageTheme";
import { formatDate } from "../../helpers/format_helper";
import ButtonTheme from "../../Components/Common/ButtonTheme";
import InputTheme from "../../Components/Common/InputTheme";
import SelectTheme from "../../Components/Common/SelectTheme";
import useMemoizedSelector from "../../Components/Hooks/useMemoizedSelector";

import { getListCompany, getListUser } from "../../store/actions";
import TableContainerCustom from "../../Components/Common/TableContainerCustom";

function ListUser({ t }) {
  const [openModalUser, setOpenModalUser] = useState(false);

  const [valueCompany, setValueCompany] = useState("");
  const [isOpenChangePassword, setIsOpenChangePassword] = useState(false);
  const [nameChangePassword, setNameChangePassword] = useState("");

  const dispatch = useDispatch();

  const { listCompany, loading, users, loadingUser } = useMemoizedSelector((state) => ({
    listCompany: state.Company.listCompany,
    loading: state.Company.loading,
    users: state.User.users,
    loadingUser: state.User.loadingUser,
  }));

  useEffect(() => {
    dispatch(getListCompany());
  }, [dispatch]);

  const handleChangeChannel = (value) => {
    setValueCompany(value.value);
    dispatch(getListUser({ company_id: value.value }));
  };

  const mapUsers = (users || []).map((user) => {
    let role = "";
    for (let index = 0; index < user.Attributes.length; index++) {
      const element = user.Attributes[index];
      if (element.Name === "custom:role") role = element.Value;
    }
    return {
      userName: user.Username,
      userStatus: user.UserStatus,
      userCreateDate: formatDate(user.UserCreateDate),
      userLastModifiedDate: formatDate(user.UserLastModifiedDate),
      role: role,
    };
  });
  const headers = [
    { title: "User Name", dataIndex: "userName" },
    { title: "Status User", dataIndex: "userStatus" },
    { title: "Create Date", dataIndex: "userCreateDate" },
    { title: "Modified Date", dataIndex: "userLastModifiedDate" },
    { title: "Role", dataIndex: "role" },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, data, index) => {
        return (
          <div>
            <div>
              <UncontrolledDropdown dir="start">
                <DropdownToggle tag="button" className="btn btn-light btn-icon btn-sm dropdown" id="dropdownMenuButton">
                  <i className="ri-more-fill align-bottom" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end" container="body">
                  <DropdownItem
                    onClick={() => {
                      setNameChangePassword(data.userName);
                      setIsOpenChangePassword(true);
                    }}
                  >
                    {t("Change Password")}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <ContentPageTheme title="List User" pageTitle="User">
        <ModalDetailUser
          listCompany={listCompany}
          isOpen={openModalUser}
          toggler={() => {
            setOpenModalUser(false);
          }}
          defaultCompany={valueCompany}
        />
        <ModalChangePassword
          isOpen={isOpenChangePassword}
          toggler={() => setIsOpenChangePassword(false)}
          idCompany={valueCompany.value}
          userName={nameChangePassword}
          t={t}
        />
        <Card>
          <CardBody>
            <div className="hstack gap-2 mb-3">
              <SelectTheme
                isForm={false}
                options={listCompany.map((company) => ({ value: company.id, label: company.name }))}
                onChange={handleChangeChannel}
                value={valueCompany}
                placeholder={"Select..."}
                classWrapper="flex-1"
              />
              <ButtonTheme type="button" className="btn btn-warning" onClick={() => setOpenModalUser(true)}>
                Add User
              </ButtonTheme>
            </div>

            <TableContainerCustom
              listFilter={[]}
              headers={headers}
              contents={mapUsers}
              // limitPage={Number(limit)}
              // currentPage={Number(page) + 1}
              // totalItems={total}
              loading={loadingUser}
              showPagination={false}
              // scrollHeight="50vh"
              tableView={true}
              onCallData={(dataPayload) => {
                dispatch(getListUser({ company_id: valueCompany, ...dataPayload }));
              }}
            />
          </CardBody>
        </Card>
      </ContentPageTheme>
    </div>
  );
}
export default withTranslation()(ListUser);

const ModalChangePassword = ({ toggler, isOpen, idCompany, userName, t }) => {
  const [loading, setLoading] = useState(false);
  const form = {
    initialValues: {
      username: userName,
      password: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({}),
    onSubmit: async (values, actions) => {
      setLoading(true);
      try {
        if (idCompany) {
          values.company_id = idCompany;
        }
        await changePasswordApi.create(values);
        actions.resetForm();
        toggler();
        toast.success(t("Change Password success!"));
      } catch (error) {
        toast.error(t("Error"));
      }
      setLoading(false);
    },
  };
  return (
    <Modal id="createFileModal" isOpen={isOpen} modalClassName="zoomIn" centered tabIndex="-1">
      <ModalHeader toggle={toggler} className="p-3 bg-soft-warning">
        {t("Change Password")}
      </ModalHeader>
      <ModalBody>
        <>
          <Formik
            enableReinitialize={true}
            initialValues={form.initialValues}
            validationSchema={form.validationSchema}
            onSubmit={form.onSubmit}
          >
            {() => (
              <Form>
                <InputTheme name="username" label={"User Name"} />
                <InputTheme name="password" label={"Password"} type="password" />

                <div className="text-end">
                  <ButtonTheme type="submit" className="btn btn-warning w-sm" loading={loading}>
                    {t("Change")}
                  </ButtonTheme>
                  <ButtonTheme
                    type="button"
                    className="btn btn-danger w-sm mx-2"
                    onClick={() => {
                      toggler();
                    }}
                  >
                    {t("Close")}
                  </ButtonTheme>
                </div>
              </Form>
            )}
          </Formik>
        </>
      </ModalBody>
    </Modal>
  );
};
