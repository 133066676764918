import { toast } from "react-toastify";
import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import "react-toastify/dist/ReactToastify.css";

// api helper
import { uploadImageApi } from "../../helpers/resource_helper";

import { UPLOAD_IMAGE } from "./actionTypes";

import { uploadImageFail, uploadImageSuccess } from "./actions";

function* uploadImage({ payload: { type, name, image } }) {
  try {
    const response = yield call(uploadImageApi.create, { name, image });
    yield put(uploadImageSuccess({ type, response }));
    toast.success("Upload Image Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(uploadImageFail(error));
    toast.error("Image Updated Failed", { autoClose: 3000 });
  }
}
export function* watchUploadImage() {
  yield takeEvery(UPLOAD_IMAGE, uploadImage);
}

function* appsSaga() {
  yield all([fork(watchUploadImage)]);
}

export default appsSaga;
