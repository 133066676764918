import {
  UPLOAD_IMAGE,
  UPLOAD_IMAGE_FAIL,
  UPLOAD_IMAGE_SUCCESS,
} from "./actionTypes";

const INIT_STATE = {
  error: null,
  loading: false,
};

const ImageUploader = (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPLOAD_IMAGE:
      return {
        ...state,
        loading: true,
      };
    case UPLOAD_IMAGE_SUCCESS:
      state[action.payload.type] = action.payload.response ?? {};
      return {
        ...state,
        loading: false,
      };
    case UPLOAD_IMAGE_FAIL:
      state[action.payload.type] = {};
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return { ...state };
  }
};

export default ImageUploader;
