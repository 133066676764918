import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { withTranslation } from "react-i18next";

import { Button, Card, Col, Container, Row, Form, Label, Input, FormFeedback, Alert, CardBody } from "reactstrap";
import PropTypes from "prop-types";

//formik
import { useFormik } from "formik";
import * as Yup from "yup";

import ButtonTheme from "../../Components/Common/ButtonTheme";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { useProfile } from "../../Components/Hooks/UserHooks";
import onexapisLogo from "../../assets/images/onexapis-logo.png";
import useMemoizedSelector from "../../Components/Hooks/useMemoizedSelector";

import { resetPass, resetResetPassFlag } from "../../store/auth/resetpwd/actions";

const ResetPasswordPage = ({ t }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useNavigate();

  const userProfile = useProfile();

  const [passwordShow, setPasswordShow] = useState(false);
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      password: "",
      confirm_password: "",
      code: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(8, t("Password must be at least 8 characters"))
        .matches(RegExp("(.*[a-z].*)"), t("At least lowercase letter"))
        .matches(RegExp("(.*[A-Z].*)"), t("At least uppercase letter"))
        .matches(RegExp("(.*[0-9].*)"), t("At least one number"))
        .matches(/[/\W|_/g]/, "Password requires a symbol")
        .required(t("Please Enter Your Password")),
      confirm_password: Yup.string()
        .oneOf([Yup.ref("password"), null], t("Passwords must match"))
        .required(t("Confirm password is required")),
      code: Yup.string().required(t("Please Enter Your Code")),
    }),
    onSubmit: (values) => {
      const dataPayload = { username: location.state.username, newPassword: values.password, code: values.code };
      dispatch(resetPass(dataPayload));
    },
  });
  const { error, loading, resetPassMsg } = useMemoizedSelector((state) => ({
    error: state.ResetPassword.error,
    loading: state.ResetPassword.loading,
    resetPassMsg: state.ResetPassword.resetPassMsg,
  }));

  useEffect(() => {
    if (resetPassMsg) {
      setTimeout(() => {
        dispatch(resetResetPassFlag());
        history("/login");
      }, 3000);
    }
    if (error) {
      setTimeout(() => dispatch(resetResetPassFlag()), 3000);
    }
  }, [dispatch, resetPassMsg, error, history]);

  document.title = `${t("Create New Password")} | OneXAPIs`;

  if (userProfile) {
    history("/my-drive");
  }

  return (
    <ParticlesAuth>
      <div className="auth-page-content">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center mt-sm-0 mb-0 text-white-50">
                <div>
                  <Link to="/#" className="d-inline-block auth-logo">
                    <img src={onexapisLogo} alt="" height="100" />
                  </Link>
                </div>
                <p className="mt-3 fs-15 fw-medium">All-in-One Automation and Integration Solution</p>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="mt-4">
                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-warning">{t("Create new password")}</h5>
                    <p className="text-muted">
                      {t("Your new password must be different from previous used password.")}
                    </p>
                  </div>
                  <div className="p-2">
                    {error && error ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {t(error)}
                      </Alert>
                    ) : null}
                    {resetPassMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {t(resetPassMsg)}
                      </Alert>
                    ) : null}
                    <Form onSubmit={validation.handleSubmit} action="/auth-signin-basic">
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="password-input">
                          {t("Password")}
                        </Label>
                        <div className="position-relative auth-pass-inputgroup">
                          <Input
                            type={passwordShow ? "text" : "password"}
                            className="form-control pe-5 password-input"
                            placeholder={t("Enter password")}
                            id="password-input"
                            name="password"
                            value={validation.values.password}
                            onBlur={validation.handleBlur}
                            onChange={validation.handleChange}
                            invalid={validation.errors.password && validation.touched.password ? true : false}
                          />
                          {validation.errors.password && validation.touched.password ? (
                            <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                          ) : null}
                          <Button
                            color="link"
                            onClick={() => setPasswordShow(!passwordShow)}
                            className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                            id="password-addon"
                          >
                            <i className="ri-eye-fill align-middle"></i>
                          </Button>
                        </div>
                        {/* <div id="passwordInput" className="form-text">
                                Must be at least 8 characters.
                              </div> */}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label" htmlFor="confirm-password-input">
                          {t("Confirm Password")}
                        </Label>
                        <div className="position-relative auth-pass-inputgroup mb-3">
                          <Input
                            type={confirmPasswordShow ? "text" : "password"}
                            className="form-control pe-5 password-input"
                            placeholder={t("Confirm password")}
                            id="confirm-password-input"
                            name="confirm_password"
                            value={validation.values.confirm_password}
                            onBlur={validation.handleBlur}
                            onChange={validation.handleChange}
                            invalid={
                              validation.errors.confirm_password && validation.touched.confirm_password ? true : false
                            }
                          />
                          {validation.errors.confirm_password && validation.touched.confirm_password ? (
                            <FormFeedback type="invalid">{validation.errors.confirm_password}</FormFeedback>
                          ) : null}
                          <Button
                            color="link"
                            onClick={() => setConfirmPasswordShow(!confirmPasswordShow)}
                            className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                          >
                            <i className="ri-eye-fill align-middle"></i>
                          </Button>
                        </div>
                      </div>

                      <div className="mb-3">
                        <Label className="form-label" htmlFor="reset-password-code-input">
                          {t("Code")}
                        </Label>
                        <div className="position-relative auth-pass-inputgroup mb-3">
                          <Input
                            type="text"
                            className="form-control pe-5 password-input"
                            placeholder={t("Reset password code")}
                            id="reset-password-code-input"
                            name="code"
                            value={validation.values.code}
                            onBlur={validation.handleBlur}
                            onChange={validation.handleChange}
                            invalid={validation.errors.code && validation.touched.code ? true : false}
                          />
                          {validation.errors.code && validation.touched.code ? (
                            <FormFeedback type="invalid">{validation.errors.code}</FormFeedback>
                          ) : null}
                        </div>
                      </div>

                      <div className="form-check">
                        <Input className="form-check-input" type="checkbox" value="" id="auth-remember-check" />
                        <Label className="form-check-label" htmlFor="auth-remember-check">
                          {t("Remember me")}
                        </Label>
                      </div>

                      <div className="mt-4">
                        <ButtonTheme className="btn btn-warning w-100" type="submit" loading={loading}>
                          Reset Password
                        </ButtonTheme>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-4 text-center">
                <p className="mb-0">
                  {t("Wait, I remember my password")}...{" "}
                  <Link to="/auth-signin-cover" className="fw-semibold text-warning text-decoration-underline">
                    {t("Click here")}{" "}
                  </Link>{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </ParticlesAuth>
  );
};

ResetPasswordPage.propTypes = {
  history: PropTypes.object,
  username: PropTypes.string,
};

export default withTranslation()(ResetPasswordPage);
