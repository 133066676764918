import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { withTranslation } from "react-i18next";

const BreadCrumb = ({ title, pageTitle, isBack = false, navigateTo = "#", t, tagIcon, pathDelete = false }) => {
  return (
    <React.Fragment>
      <Row>
        <Col xs={12}>
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              {isBack && (
                <Link to={-1}>
                  <i className="bx bx-arrow-back cursor-pointer fs-22 px-2" />
                </Link>
              )}
              {tagIcon}
              <h4 className="mb-sm-0" style={{ marginTop: "2px", marginLeft: "8px" }}>
                {t(Array.isArray(title) ? title[title.length - 1] : title)}
              </h4>
            </div>

            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                {!pathDelete && (
                  <li className="breadcrumb-item">
                    <Link to={navigateTo}>{t(pageTitle)}</Link>
                  </li>
                )}
                {Array.isArray(title) ? (
                  title.map((item, index, arr) => {
                    if (index === 0 && pathDelete) {
                      return (
                        <li className="breadcrumb-item active" key={index}>
                          <Link to="/deleted">{item}</Link>
                        </li>
                      );
                    } else {
                      const link = "/folders/" + encodeURIComponent(arr.slice(0, index + 1).join("/") + "/");
                      return (
                        <li className="breadcrumb-item active" key={index}>
                          {arr.length - 1 !== index ? <Link to={link}>{item}</Link> : item}
                        </li>
                      );
                    }
                  })
                ) : (
                  <li className="breadcrumb-item active">{t(title)}</li>
                )}
              </ol>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default withTranslation()(BreadCrumb);
