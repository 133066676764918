import React from "react";
import BreadCrumb from "./BreadCrumb";
import { Container } from "reactstrap";

export default function ContentPageTheme({ children, pageTitle, title, isBack }) {
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title={title} pageTitle={pageTitle} isBack={isBack} />
        {children}
      </Container>
    </div>
  );
}
