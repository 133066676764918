import { Pagination } from "antd";
import { useTranslation } from "react-i18next";
import { Input } from "reactstrap";

const PaginationCustom = ({ currentPage, totalItems, limit = 10, payload = {}, onGetApi = () => {} }) => {
  const { t } = useTranslation();
  const limitOptions = [1, 2, 5, 10, 20, 50];

  const handleChangeLimit = (data) => {
    const dataPayload = { ...payload, limit: data };
    dataPayload.page && delete dataPayload.page;
    onGetApi(dataPayload);
  };

  const handleChangePage = (page) => {
    const data = { ...payload, page, limit: limit };
    onGetApi(data);
  };
  return (
    <div className="d-flex justify-content-between">
      <div>
        <div className="d-flex align-items-center text-muted">
          <span className="me-2">{t("Showing")}</span>
          <div className=" me-2">
            <Input
              id="limit-field"
              className="form-select"
              type="select"
              onChange={(e) => handleChangeLimit(e.target.value)}
              value={limit}
            >
              {limitOptions.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </Input>
          </div>
          <span className="me-2">{t("results")}.</span>
          <span>
            {t("From")} <b>{(currentPage - 1) * limit + 1}</b> {t("to")}{" "}
            <b>{currentPage * limit > totalItems ? totalItems : currentPage * limit}</b> {t("of a total of")}{" "}
            <b>{totalItems}</b>
          </span>
        </div>
      </div>
      <div>
        <Pagination
          current={currentPage}
          pageSize={limit}
          total={totalItems}
          onChange={(page) => {
            handleChangePage(page - 1);
          }}
          showQuickJumper
        />
      </div>
    </div>
  );
};

export default PaginationCustom;
