import React, { useRef, useState } from "react";
import ModalContainer from "../../../Components/Common/ModalContainer";
import { FieldArray, Form, Formik, useFormikContext } from "formik";
import { toast } from "react-toastify";
import InputTheme from "../../../Components/Common/InputTheme";
import UploadTheme from "../../../Components/Common/UploadTheme";
import { Label } from "reactstrap";
import ButtonTheme from "../../../Components/Common/ButtonTheme";
import { cloneObjectOrArray, convertArrToObject, objectToArray } from "../../../common/constants";
import { servicedApi } from "../../../helpers/resource_helper";
export default function ModalService({ dataService = {}, open, toggle, onChangeService }) {
  const [loading, setLoading] = useState(false);
  const refForm = useRef();
  const formValidation = {
    initialValues: {
      name: dataService?.name || "",
      code: dataService?.code || "",
      description: dataService.description || "",
      features: objectToArray(dataService?.features, "Key", "Description") || [],
      logo: dataService.logo || "",
    },
    onSubmit: async (values, actions) => {
      setLoading(true);
      const cloneValues = cloneObjectOrArray(values);
      cloneValues.features = convertArrToObject(values.features, "Key", "Description");
      try {
        if (dataService.id) {
          ///log
          const res = await servicedApi.put({ ...dataService, ...cloneValues });
          onChangeService({ ...res, id: dataService.id }, dataService.id);
        } else {
          cloneValues.plans = [];
          const res = await servicedApi.create(cloneValues);
          onChangeService(res);
        }
        actions.resetForm();
        toggle();
      } catch (error) {
        toast.error("Error!");
      }
      setLoading(false);
    },
  };
  return (
    <ModalContainer
      isOpen={open}
      title={"Add Service"}
      toggle={toggle}
      actionCustom={
        <div className="hstack gap-2 justify-content-end mt-3">
          <ButtonTheme
            className="btn btn-warning w-sm"
            loading={loading}
            onClick={() => {
              refForm.current.handleSubmit();
            }}
          >
            {dataService.id ? "Edit" : "Create"}
          </ButtonTheme>
          <ButtonTheme
            type="button"
            className="btn btn-danger w-sm mx-2"
            onClick={() => {
              toggle();
            }}
          >
            Close
          </ButtonTheme>
        </div>
      }
    >
      <Formik
        enableReinitialize={true}
        initialValues={formValidation.initialValues}
        onSubmit={formValidation.onSubmit}
        innerRef={refForm}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <InputTheme name="name" label={"Name"} />
            <InputTheme name="code" label={"Code"} />
            <InputTheme name="description" type="textarea" label={"Description"} />
            <UploadTheme name="logo" label={"Logo"} />
            <Label>Features</Label>
            <FieldArray name="features">
              {({ push, remove }) => (
                <div>
                  {values.features.map((item, index) => (
                    <div key={index} className="box-setting p-1">
                      <div className="align-items-center d-flex">
                        <InputTheme name={`features.${index}.Key`} label={"Key"} classWrapper="flex-1" />
                        <InputTheme
                          name={`features.${index}.Description`}
                          label={"Description"}
                          classWrapper="flex-1"
                        />
                        <div>
                          <button
                            className="btn btn-danger mx-3 p-1 hover-border-box mt-2"
                            type="button"
                            onClick={() => remove(index)}
                            style={{
                              height: "30px",
                            }}
                          >
                            <i className="bx bx-minus fs-22"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                  <button
                    className="btn btn-info btn-add p-1"
                    type="button"
                    onClick={() => push({})}
                    style={{
                      height: "32px",
                      width: "38px",
                    }}
                  >
                    <i className="bx bx-plus-medical fs-21"></i>
                  </button>
                </div>
              )}
            </FieldArray>
          </Form>
        )}
      </Formik>
    </ModalContainer>
  );
}
