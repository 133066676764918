import {
  GET_DETAILS_COMPANY,
  GET_DETAILS_COMPANY_FAIL,
  GET_DETAILS_COMPANY_SUCCESS,
  LIST_COMPANY,
  LIST_COMPANY_ERROR,
  LIST_COMPANY_SUCCESS,
} from "./actionTypes";

const INIT_STATE = {
  error: null,
  loading: false,
  listCompany: [],
  company: {},
  success: false,
  message: "",
  limit: 0,
  page: 0,
  total: 0,
};

const Company = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_COMPANY:
      return {
        ...state,
        loading: true,
      };
    case LIST_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        listCompany: action.payload?.items,
        message: "List flow success",
        success: true,
        limit: action.payload.limit ?? 0,
        page: action.payload.page ?? 0,
        total: action.payload.total ?? 0,
      };
    case LIST_COMPANY_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_DETAILS_COMPANY:
      return {
        ...state,
        loading: true,
      };
    case GET_DETAILS_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        company: action.payload,
      };
    case GET_DETAILS_COMPANY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return { ...state };
  }
};

export default Company;
