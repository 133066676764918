import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from "reactstrap";

import Loader from "../../Components/Common/Loader";
import ContentPageTheme from "../../Components/Common/ContentPageTheme";
import useMemoizedSelector from "../../Components/Hooks/useMemoizedSelector";

import { getListCompany, listSubscription } from "../../store/actions";
import FilterComponent from "../../Components/Common/FilterComponent";
import PaginationCustom from "../../Components/Common/PaginationCustom";
import { Table } from "antd";
import moment from "moment";
import TableContainerCustom from "../../Components/Common/TableContainerCustom";
import { convertValidDate, convertValidTime } from "../../helpers/format_helper";

function ListCompany({ t }) {
  const { listCompany, loading, limit, message, total, page } = useMemoizedSelector((state) => ({
    listCompany: state.Company.listCompany,
    loading: state.Company.loading,
    message: state.Company.message,
    limit: state.Company.limit,
    total: state.Company.total,
    page: state.Company.page,
  }));
  // const loading = fa;

  const [payload, setPayload] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const headers = ["name", "email", "contact_name", "action"];

  useEffect(() => {
    dispatch(getListCompany({ limit: 10 }));
  }, [dispatch]);

  return (
    <div>
      <ContentPageTheme title="List Company" pageTitle="Company">
        <Card>
          <CardBody>
            <TableContainerCustom
              filterType={"subscriptions"}
              tableView={true}
              searchPlaceHolder={"Search..."}
              listFilter={[]}
              headers={headers}
              contents={listCompany}
              limitPage={Number(limit)}
              currentPage={Number(page) + 1}
              totalItems={total}
              showPagination={true}
              scrollHeight="50vh"
              onCallData={(dataPayload) => {
                dispatch(getListCompany(dataPayload));
              }}
              loading={loading}
              isSettings={true}
              listHeader={[
                {
                  title: t("Product details"),
                  key: "detail-product",
                  children: [
                    {
                      title: t("Name"),
                      dataIndex: "name",
                      key: "name",
                      width: "35%",
                      sorter: true,
                      render: (value, row, index) => {
                        return (
                          <div className={"team-profile-img d-flex align-items-center mx-2"}>
                            <div className=" avatar-md img-thumbnail rounded-circle flex-shrink-0 ">
                              <img
                                src={row.logo}
                                alt=""
                                className="img-fluid d-block rounded-circle w-100 h-100"
                                style={{ objectFit: "contain" }}
                              />
                            </div>
                            <div className="team-content mx-3">
                              <h5 className="fs-16 mb-1">{row?.name}</h5>
                              <p className="text-muted mb-0">{row?.domain}</p>
                            </div>
                          </div>
                        );
                      },
                    },
                    {
                      title: t("Email"),
                      dataIndex: "email",
                      key: "email",
                      width: "30%",
                      sorter: true,
                      render: (value, row, index) => {
                        return (
                          <h5 className="mb-1 d-flex">
                            <i className="mdi mdi-email mx-2"></i> <strong>{value}</strong>
                          </h5>
                        );
                      },
                    },
                    {
                      title: "Contact Name",
                      dataIndex: "contact_name",
                      key: "contact_name",
                      width: "20%",
                      sorter: true,
                      render: (value, row, index) => {
                        return (
                          <h5 className="mb-1 d-flex">
                            <i className="ri-contacts-fill mx-2"></i> <strong>{value}</strong>
                          </h5>
                        );
                      },
                    },
                    {
                      title: t("Action"),
                      dataIndex: "action",
                      key: "action",
                      render: (value, row, index) => {
                        return (
                          <UncontrolledDropdown direction="start" className="col text-end">
                            <DropdownToggle tag="a" id="dropdownMenuLink2" role="button">
                              <i className="ri-more-fill fs-17"></i>
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                onClick={() => {
                                  navigate(`/company/${row.id}?view=true`);
                                }}
                              >
                                <i className="ri-eye-fill align-bottom me-2 text-muted"></i>
                                {t("View")}
                              </DropdownItem>
                              <DropdownItem
                                onClick={() => {
                                  navigate(`/company/${row.id}`);
                                }}
                              >
                                <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>
                                {t("Edit")}
                              </DropdownItem>
                              {/* <DropdownItem >
                                <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i> Remove
                              </DropdownItem> */}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        );
                      },
                    },
                  ],
                },
              ]}
            />
          </CardBody>
        </Card>
      </ContentPageTheme>
    </div>
  );
}

export default withTranslation()(ListCompany);
