import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// api helper
import { GET_DETAILS_COMPANY, LIST_COMPANY } from "./actionTypes";

import { getDetailCompanyByIdFail, getDetailCompanyByIdSuccess, listCompanyError, listCompanySuccess } from "./actions";
import { companiesApi } from "../../helpers/resource_helper";

function* onListCompany({ payload }) {
  try {
    const response = yield call(companiesApi.list, payload);
    yield put(listCompanySuccess(response));
  } catch (error) {
    yield put(listCompanyError());
  }
}

function* onGetDetailCompanyById({ payload }) {
  try {
    const response = yield call(companiesApi.get, payload);
    yield put(getDetailCompanyByIdSuccess(response));
  } catch (error) {
    yield put(getDetailCompanyByIdFail(error));
  }
}

export function* watchListCompany() {
  yield takeEvery(LIST_COMPANY, onListCompany);
  yield takeEvery(GET_DETAILS_COMPANY, onGetDetailCompanyById);
}

function* companySaga() {
  yield all([fork(watchListCompany)]);
}

export default companySaga;
