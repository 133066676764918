import React, { useEffect, useState } from "react";
//redux
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form } from "reactstrap";
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import onexapisLogo from "../../assets/images/onexapis-logo.png";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import ButtonTheme from "../../Components/Common/ButtonTheme";
import useMemoizedSelector from "../../Components/Hooks/useMemoizedSelector";

// action
import { resendConfirmationCode, resetRegisterFlag, verifyConfirmationCode } from "../../store/actions";

const VerifyConfirmationCodePage = ({ t }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useNavigate();

  const [action, setAction] = useState("");

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      confirmationCode: "",
    },
    validationSchema: Yup.object({
      confirmationCode: Yup.string().required(t("Please Enter Verification Code Sent To your Email")),
    }),
    onSubmit: (values) => {
      setAction("Verify");
      dispatch(verifyConfirmationCode({ username: location.state.email, code: values.confirmationCode }));
    },
  });

  const { error, registrationError, success, loading, resendLoading, resendMessage, resendError, resendSuccess } =
    useMemoizedSelector((state) => ({
      registrationError: state.Account.registrationError,
      success: state.Account.success,
      error: state.Account.error,
      loading: state.Account.loading,
      resendLoading: state.Account.resendLoading,
      resendError: state.Account.resendError,
      resendSuccess: state.Account.resendSuccess,
      resendMessage: state.Account.resendMessage,
    }));

  useEffect(() => {
    if (success) {
      setTimeout(() => history("/login"), 3000);
    }
    if (!loading && (error || resendError)) {
      let resetErrorMsgTime = setTimeout(() => dispatch(resetRegisterFlag()), 3000);
      return () => clearTimeout(resetErrorMsgTime);
    }
  }, [dispatch, success, error, history, loading, resendError, resendLoading]);

  document.title = `${t("Verify Confirmation Code")} | OneXAPIs`;
  return (
    <ParticlesAuth>
      <div className="auth-page-content">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center mt-sm-0 mb-0 text-white-50">
                <div>
                  <Link to="/" className="d-inline-block auth-logo">
                    <img src={onexapisLogo} alt="" height="100" />
                  </Link>
                </div>
                <p className="mt-3 fs-15 fw-medium">All-in-One Automation and Integration Solution</p>
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="mt-4">
                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-warning">{t("Verify Confirmation Code")}</h5>
                    <p className="text-muted">{t("Verify Confirmation Code with OneXAPIs")}</p>

                    <lord-icon
                      src="https://cdn.lordicon.com/rhvddzym.json"
                      trigger="loop"
                      colors="primary:#0ab39c"
                      className="avatar-xl"
                      style={{ width: "120px", height: "120px" }}
                    ></lord-icon>
                  </div>

                  <Alert className="alert-borderless alert-warning text-center mb-2 mx-2" role="alert">
                    {t("Enter Confirmation Code Sent To Your Email!")}
                  </Alert>
                  <div className="p-2">
                    {error && registrationError ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {t(registrationError)}
                      </Alert>
                    ) : null}
                    {success ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {/* {verifySuccessMsg} */}
                        {t(`${action} Confirmation Code Successfully`)}
                      </Alert>
                    ) : null}
                    {resendError && resendMessage ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {t(resendMessage)}
                      </Alert>
                    ) : null}
                    {resendSuccess && resendMessage ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {t(resendMessage)}
                      </Alert>
                    ) : null}
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-4">
                        <Label className="form-label">{t("Confirmation Code")}</Label>
                        <Input
                          name="confirmationCode"
                          className="form-control"
                          placeholder={t("Enter confirmation code")}
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.confirmationCode || ""}
                          invalid={
                            validation.touched.confirmationCode && validation.errors.confirmationCode ? true : false
                          }
                        />
                        {validation.touched.confirmationCode && validation.errors.confirmationCode ? (
                          <FormFeedback type="invalid">
                            <div>{validation.errors.confirmationCode}</div>
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="text-center mt-4">
                        <ButtonTheme className="btn btn-warning w-100" type="submit" loading={loading}>
                          Verify Confirmation Code
                        </ButtonTheme>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>

              <div className="mt-4 text-center">
                <p className="mb-0">
                  {t("I didn't receive any comfirmation code.")}
                  <button
                    className="fw-semibold text-warning text-decoration-underline border-0"
                    onClick={() => {
                      setAction("Resend");
                      dispatch(resendConfirmationCode(location.state.email));
                    }}
                    disabled={resendLoading}
                  >
                    {t("Resend here")}
                  </button>
                </p>
              </div>

              <div className="mt-4 text-center">
                <p className="mb-0">
                  {t("Already have an account?")}{" "}
                  <Link to="/login" className="fw-semibold text-warning text-decoration-underline">
                    {t("Sign in")}
                  </Link>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </ParticlesAuth>
  );
};

VerifyConfirmationCodePage.propTypes = {
  history: PropTypes.object,
  email: PropTypes.string,
};

export default withTranslation()(VerifyConfirmationCodePage);
