import React from "react";
import { withTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import ButtonTheme from "./ButtonTheme";
import PropTypes from "prop-types";
function ModalContainer({
  title,
  actionCustom = false,
  size = "",
  children,
  isOpen,
  toggle,
  fullscreen = false,
  t,
  hiddenDefaultButton = true,
}) {
  return (
    <Modal
      id="showModal"
      isOpen={isOpen}
      centered
      size={size}
      toggle={toggle}
      fullscreen={fullscreen}
      scrollable={true}
    >
      <ModalHeader className="bg-light p-3" toggle={toggle}>
        {t(title)}
      </ModalHeader>
      <ModalBody>{children}</ModalBody>

      {hiddenDefaultButton && (
        <ModalFooter>
          {actionCustom ? (
            actionCustom
          ) : (
            <div className="hstack gap-2 justify-content-end mt-3">
              <ButtonTheme
                type="button"
                className="btn btn-danger w-sm mx-2"
                onClick={() => {
                  toggle();
                }}
              >
                Close
              </ButtonTheme>
            </div>
          )}
        </ModalFooter>
      )}
    </Modal>
  );
}

ModalContainer.propTypes = {
  title: PropTypes.string,
  actionCustom: PropTypes.object,
  size: PropTypes.string,
  children: PropTypes.object,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  fullscreen: PropTypes.bool,
  hiddenDefaultButton: PropTypes.bool,
};

export default withTranslation()(ModalContainer);
