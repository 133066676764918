import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { LIST_USER } from "./actionTypes";
import { listUserError, listUserSuccess } from "./actions";
import { userApi } from "../../helpers/resource_helper";

// api helper

function* onListUser({ payload }) {
  try {
    const response = yield call(userApi.list, payload);
    yield put(listUserSuccess(response.Users, response.NextToken));
  } catch (error) {
    yield put(listUserError());
  }
}
export function* watchListUser() {
  yield takeEvery(LIST_USER, onListUser);
}

function* userSaga() {
  yield all([fork(watchListUser)]);
}

export default userSaga;
