import { GET_DETAILS_COMPANY, GET_DETAILS_COMPANY_FAIL, GET_DETAILS_COMPANY_SUCCESS, LIST_COMPANY, LIST_COMPANY_ERROR, LIST_COMPANY_SUCCESS } from "./actionTypes";

export const getListCompany = (data) => ({
  type: LIST_COMPANY,
  payload: data,
});

export const listCompanySuccess = (data) => ({
  type: LIST_COMPANY_SUCCESS,
  payload: data,
});

export const listCompanyError = () => ({
  type: LIST_COMPANY_ERROR,
});

export const getDetailCompanyById = (data) => ({
  type: GET_DETAILS_COMPANY,
  payload: data,
});

export const getDetailCompanyByIdSuccess = (data) => ({
  type: GET_DETAILS_COMPANY_SUCCESS,
  payload: data,
});

export const getDetailCompanyByIdFail = (error) => ({
  type: GET_DETAILS_COMPANY_FAIL,
  payload: error,
});
