import React from "react";
import ModalContainer from "../../Components/Common/ModalContainer";
import { Form, Formik } from "formik";
import InputTheme from "../../Components/Common/InputTheme";
import { Col, Row } from "reactstrap";

export default function ModalMessage({ open, toggle, dataMessage }) {
  const form = {
    initialValues: {
      name: dataMessage.name || "",
      email: dataMessage.email || "",
      subject: dataMessage.subject || "",
      message: dataMessage.message || "",
      phone: dataMessage.phone || "",
    },
    enableReinitialize: true,

    onSubmit: async (values, actions) => {},
  };
  return (
    <div>
      <ModalContainer isOpen={open} title={"View Message"} toggle={toggle}>
        <Formik
          enableReinitialize={true}
          initialValues={form.initialValues}
          validationSchema={form.validationSchema}
          onSubmit={form.onSubmit}
        >
          {() => (
            <Form>
              <Row>
                <Col lg={4}>
                  <InputTheme name="name" label={"Name"} placeholder="Your name*" disabled={true}/>
                </Col>
                <Col lg={4}>
                  <InputTheme name="email" label={"Email"} placeholder="Your email*" disabled={true}/>
                </Col>
                <Col lg={4}>
                  <InputTheme name="phone" label={"Phone"} placeholder="Your phone*" disabled={true}/>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <InputTheme name="subject" label={"Subject"} placeholder="Your Subject.." disabled={true}/>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <InputTheme name="message" label={"Message"} placeholder="Your message..." type="textarea" disabled={true}/>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </ModalContainer>
    </div>
  );
}
