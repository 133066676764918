import moment from "moment";

export const formatCurrency = (x) => {
  x = parseInt(x ? x : "0");
  return x.toLocaleString("vn-VN", {
    style: "currency",
    currency: "VND",
  });
};

export const formatNumber = (x) => {
  x = x ? x : "0";
  return x.toLocaleString("vn-VN", {
    currency: "VND",
  });
};

export const formatDateTime = (x) => {
  try {
    return new Date(x).toLocaleString("vn-VN", { hour12: false });
  } catch (error) {
    return "";
  }
};

export const formatDate = (x) => {
  try {
    return moment(x).calendar();
  } catch (error) {
    return "";
  }
};
export const formatFromNow = (x) => {
  try {
    return moment(x).fromNow();
  } catch (error) {
    return "";
  }
};
export const formatDate1 = (date) => {
  const currentDate = new Date(date);
  const year = currentDate.getFullYear();
  const month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
  const day = ("0" + currentDate.getDate()).slice(-2);
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

export const convertValidDate = (date) => {
  const date1 = moment(new Date(date)).format("DD MMM Y");
  return date1;
};

export const convertValidTime = (time) => {
  const time1 = new Date(time);
  const getHour = time1.getHours();
  const getMin = time1.getMinutes();
  const getTime = `${getHour}:${getMin}`;
  var meridiem = "";
  if (getHour >= 12) {
    meridiem = "PM";
  } else {
    meridiem = "AM";
  }
  const updateTime = moment(getTime, "hh:mm").format("hh:mm") + " " + meridiem;
  return updateTime;
};


export const formatVNDCurrency = (x) => {
  const VND = new Intl.NumberFormat("vi-VN", {
    style: "currency",
    currency: "VND",
  });
  try {
    const price = Number(x);
    if (isNaN(price)) {
      return VND.format(0);
    } else {
      return VND.format(price);
    }
  } catch (e) {
    return VND.format(0);
  }
};