import PropTypes from "prop-types";
import React from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import ButtonTheme from "./ButtonTheme";

const StatusModal = ({ show, onClick, onCloseClick, loading, isWarring = false }) => {
  return (
    <Modal fade={true} isOpen={show} toggle={onCloseClick} centered={true} /*  backdrop={false} */>
      <ModalBody className="py-3 px-5">
        <div className="mt-2 text-center">
          <lord-icon
            src="https://cdn.lordicon.com/gsqxdxog.json"
            trigger="loop"
            colors="primary:#f7b84b,secondary:#f06548"
            style={{ width: "100px", height: "100px" }}
          ></lord-icon>
          <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
            <h4>Are you sure ?</h4>
            <p className="text-muted mx-4 mb-0">
              Are you sure you want to {isWarring ? "change" : "delete"} this record ?
            </p>
          </div>
        </div>
        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
          <Button type="button" className="btn w-sm btn-light" data-bs-dismiss="modal" onClick={onCloseClick}>
            Close
          </Button>
          <ButtonTheme
            id="delete-record"
            type="button"
            className="btn w-sm btn-danger"
            onClick={onClick}
            loading={loading}
          >{`Yes, ${isWarring ? "Change" : "Delete"} It!`}</ButtonTheme>
        </div>
      </ModalBody>
    </Modal>
  );
};

StatusModal.propTypes = {
  onCloseClick: PropTypes.func,
  onClick: PropTypes.func,
  show: PropTypes.any,
};

export default StatusModal;
