export const REGISTER_USER = "REGISTER_USER"
export const REGISTER_USER_SUCCESSFUL = "REGISTER_USER_SUCCESSFUL"
export const REGISTER_USER_FAILED = "REGISTER_USER_FAILED"
export const RESET_REGISTER_FLAG = "RESET_REGISTER_FLAG";
export const VERIFY_CONFIRMATION_CODE = "VERIFY_CONFIRMATION_CODE";
export const VERIFY_CONFIRMATION_CODE_SUCCESSFUL = "VERIFY_CONFIRMATION_CODE_SUCCESSFUL";
export const VERIFY_CONFIRMATION_CODE_FAILED = "VERIFY_CONFIRMATION_CODE_FAILED";
export const RESEND_CONFIRMATION_CODE = "RESEND_CONFIRMATION_CODE";
export const RESEND_CONFIRMATION_CODE_SUCCESSFUL = "RESEND_CONFIRMATION_CODE_SUCCESSFUL";
export const RESEND_CONFIRMATION_CODE_FAILED = "RESEND_CONFIRMATION_CODE_FAILED";
