import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import { Card, CardBody, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from "reactstrap";
import ContentPageTheme from "../../Components/Common/ContentPageTheme";
import ModalSubscription from "../Services/Components/ModalSubscription";
import useMemoizedSelector from "../../Components/Hooks/useMemoizedSelector";
import { listSubscription, pauseSubscription, resumeSubscription, suspendSubscription } from "../../store/actions";
import StatusModal from "../../Components/Common/StatusModal";
import styles from "./index.module.scss";
import { convertValidDate, convertValidTime } from "../../helpers/format_helper";
import TableContainerCustom from "../../Components/Common/TableContainerCustom";
import { SubscriptionOptions } from "../../Components/constants/common";

function Subscription({ t }) {
  const [openModalSubscription, setOpenModalSubscription] = useState(false);
  const [dataSubscription, setDataSubscription] = useState({});
  const dispatch = useDispatch();
  const headers = ["name", "plan", "contract_number", "status", "updated_at", "created_at", "action"];

  const {
    subscriptionData,
    subscriptionLoading,
    subscriptionLimit,
    subscriptionPage,
    subscriptionTotal,
    subscriptionSuccess,
    subscriptionError,
  } = useMemoizedSelector((state) => ({
    subscriptionData: state.Subscriptions.subscriptions,
    subscriptionLoading: state.Subscriptions.loading,
    subscriptionLimit: state.Subscriptions.limit,
    subscriptionPage: state.Subscriptions.page,
    subscriptionTotal: state.Subscriptions.total,
    subscriptionSuccess: state.Subscriptions.success,
    subscriptionError: state.Subscriptions.error,
  }));

  return (
    <div>
      <ContentPageTheme title="List Subscription" pageTitle="Subscription">
        <Card>
          <CardBody>
            <TableContainerCustom
              filterType={"subscriptions"}
              tableView={true}
              searchPlaceHolder={"Search..."}
              listFilter={[
                {
                  id: "status",
                  type: "selectBox",
                  title: "Type",
                  dataOption: SubscriptionOptions,
                },
                {
                  id: "company_id",
                  type: "selectBox",
                  title: "Company",
                  remote: true,
                  pathUrlLoad: "admin/companies",
                },
                {
                  id: "service_id",
                  type: "selectBox",
                  title: "Service",
                  remote: true,
                  pathUrlLoad: "admin/services",
                },
              ]}
              headers={headers}
              contents={subscriptionData}
              limitPage={Number(subscriptionLimit)}
              currentPage={Number(subscriptionPage) + 1}
              totalItems={subscriptionTotal}
              showPagination={true}
              scrollHeight="50vh"
              onCallData={(dataPayload) => {
                dispatch(listSubscription(dataPayload));
              }}
              loading={subscriptionLoading}
              isSettings={true}
              listHeader={[
                {
                  title: t("Product details"),
                  key: "detail-product",
                  children: [
                    {
                      title: t("Name"),
                      dataIndex: "name",
                      key: "name",
                      render: (value, row, index) => {
                        return (
                          <div className={"team-profile-img d-flex align-items-center mx-2"}>
                            <div className=" avatar-md img-thumbnail rounded-circle flex-shrink-0 ">
                              <img
                                src={row.company.logo}
                                alt=""
                                className="img-fluid d-block rounded-circle w-100 h-100"
                                style={{ objectFit: "contain" }}
                              />
                            </div>
                            <div className="team-content mx-3">
                              <h5 className="fs-16 mb-1">{row?.company?.name}</h5>
                              <p className="text-muted mb-0">{row?.company?.contact}</p>
                            </div>
                          </div>
                        );
                      },
                    },
                    {
                      title: t("Plan"),
                      dataIndex: "plan",
                      key: "plan",
                      render: (value, row, index) => {
                        return (
                          <div className=" d-flex align-items-center flex-column">
                            <h5 className="mb-1 d-flex justify-content-center">
                              <strong>{row?.plan?.name}</strong>
                              <span>({row?.plan?.code})</span>
                              <span>
                                : {row?.plan?.price} <sup>đ</sup>
                              </span>
                            </h5>
                            <p className="text-muted mb-0">
                              Sale price:{" "}
                              <span>
                                {row?.plan?.sale_price} <sup>đ</sup>
                              </span>
                            </p>
                          </div>
                        );
                      },
                    },
                    {
                      title: "Contract",
                      dataIndex: "contract_number",
                      key: "contract_number",
                      sorter: true,
                      render: (value, row, index) => {
                        return (
                          <div className="team-content mx-3">
                            <h5 className="fs-16 mb-1">{value}</h5>
                          </div>
                        );
                      },
                    },
                    {
                      title: "Status",
                      dataIndex: "status",
                      key: "status",
                      sorter: true,
                      render: (value, row, index) => {
                        return (
                          <div className="team-content mx-3">
                            <h5 className="fs-16 mb-1">{value}</h5>
                          </div>
                        );
                      },
                    },
                    {
                      title: t("Updated at"),
                      dataIndex: "updated_at",
                      key: "updated_at",
                      sorter: true,
                      render: (value, row, index) => {
                        return (
                          <>
                            {convertValidDate(value)},<small className="text-muted"> {convertValidTime(value)}</small>
                          </>
                        );
                      },
                    },
                    {
                      title: t("Created at"),
                      dataIndex: "created_at",
                      key: "created_at",
                      sorter: true,
                      defaultSortOrder: "desc",
                      render: (value, row, index) => {
                        return (
                          <>
                            {convertValidDate(value)},<small className="text-muted"> {convertValidTime(value)}</small>
                          </>
                        );
                      },
                    },
                    {
                      title: t("Action"),
                      dataIndex: "action",
                      key: "action",
                      render: (value, row, index) => {
                        return (
                          <UncontrolledDropdown direction="start">
                            <DropdownToggle tag="a" id="dropdownMenuLink2" role="button">
                              <i className="ri-more-fill fs-17"></i>
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                onClick={() => {
                                  setOpenModalSubscription(true);
                                  setDataSubscription(row);
                                }}
                              >
                                <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>
                                {t("Edit")}
                              </DropdownItem>
                              <DropdownItem
                                onClick={() => {
                                  dispatch(suspendSubscription(row));
                                }}
                              >
                                <i className="ri-stop-circle-line align-bottom me-2 text-muted"></i> {t("Suspend")}
                              </DropdownItem>
                              {["PAUSED"].includes(row?.status) && (
                                <DropdownItem
                                  onClick={() => {
                                    dispatch(resumeSubscription(row));
                                  }}
                                >
                                  <i className="ri-play-circle-line align-bottom me-2 text-muted"></i> {t("Resume")}
                                </DropdownItem>
                              )}
                              {["ACTIVE"].includes(row?.status) && (
                                <DropdownItem
                                  onClick={() => {
                                    dispatch(pauseSubscription(row));
                                  }}
                                >
                                  <i className="ri-pause-circle-line align-bottom me-2 text-muted"></i> {t("Pause")}
                                </DropdownItem>
                              )}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        );
                      },
                    },
                  ],
                },
              ]}
            />
          </CardBody>
        </Card>
      </ContentPageTheme>
      <ModalSubscription
        open={openModalSubscription}
        toggle={() => setOpenModalSubscription(false)}
        dataSubscription={dataSubscription}
      />
    </div>
  );
}

export default withTranslation()(Subscription);
