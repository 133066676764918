import { Resource } from "../helpers/api_helper";

export const Actions = {
  UPDATE: "UPDATE",
  CREATE: "CREATE",
  DELETE: "DELETE",
  LIST:"LIST",
  EMPTY: "",
};

export const uploadImageApi = new Resource("admin/images");
export const companiesApi = new Resource("admin/companies");
export const userApi = new Resource("admin/users");
export const changePasswordApi = new Resource("admin/setPassword");
export const servicedApi = new Resource("admin/services");
export const subscriptionApi = new Resource("admin/subscriptions");
export const messagesApi = new Resource("admin/messages");
export const saveFilterAPI = new Resource("filter/filters");

