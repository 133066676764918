import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import InputTheme from "../../Components/Common/InputTheme";
import ButtonTheme from "../../Components/Common/ButtonTheme";
import { withTranslation } from "react-i18next";
import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap";
import ContentPageTheme from "../../Components/Common/ContentPageTheme";
import UploadTheme from "../../Components/Common/UploadTheme";
import { toast } from "react-toastify";
import { useParams, useSearchParams } from "react-router-dom";
import Loader from "../../Components/Common/Loader";
import { useProfile } from "../../Components/Hooks/UserHooks";
import { companiesApi } from "../../helpers/resource_helper";

const FormCompany = ({ t }) => {
  const [loading, setLoading] = useState(false);
  const [loadDetail, setLoadDetail] = useState(false);
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [detailCompany, setDetailCompany] = useState({});

  const view = searchParams.get("view");

  useEffect(() => {
    const getDetailById = async (id) => {
      setLoadDetail(true);
      const res = await companiesApi.get({ id });
      setDetailCompany(res);
      setLoadDetail(false);
    };
    if (id) {
      getDetailById(id);
    }
  }, [id]);

  useEffect(() => {
    if (!view) {
      setDetailCompany({});
    }
  }, [view]);

  const formValidation = {
    initialValues: {
      name: detailCompany.name || "",
      domain: detailCompany.domain || "",
      logo: detailCompany.logo || "",
      contact_name: detailCompany.contact_name || "",
      email: detailCompany.email || "",
    },
    onSubmit: async (values, actions) => {
      try {
        setLoading(true);
        if (id) {
          await companiesApi.put({ id, ...values });
          toast.success(t("Edit company success!"));
        } else {
          await companiesApi.create(values);
          actions.resetForm();
          toast.success(t("Create company success!"));
        }
      } catch (error) {
        console.log(error);
        toast.error(t("Error!"));
      } finally {
        setLoading(false);
      }
    },
  };

  return (
    <ContentPageTheme
      title={id ? (view ? "View company" : "Edit company") : "Add company"}
      pageTitle="Company"
      isBack={true}
    >
      {loadDetail ? (
        <Loader />
      ) : (
        <Formik
          enableReinitialize={true}
          initialValues={formValidation.initialValues}
          // validationSchema={formValidation.validationSchema}
          onSubmit={formValidation.onSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Row>
                <Col lg={12}>
                  <Card style={{ height: "100%" }}>
                    <CardBody>
                      <InputTheme name="name" label="Name" placeholder="Enter name" disabled={view} />
                      <InputTheme name="email" label="Email" disabled={view} />
                      <InputTheme name="domain" label="Domain" disabled={view} />
                      {/* <InputTheme name="expired_at" label="Expired At" type="date" /> */}
                      <InputTheme name="contact_name" label="Contact Name" disabled={view} />

                      <UploadTheme name="logo" label="Logo" disabled={view} />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              {!view && (
                <div className="hstack gap-2 justify-content-end mt-3">
                  <ButtonTheme type="submit" className="btn btn-warning w-sm" loading={loading}>
                    {id ? "Edit" : "Create"}
                  </ButtonTheme>
                </div>
              )}
            </Form>
          )}
        </Formik>
      )}
    </ContentPageTheme>
  );
};

export default withTranslation()(FormCompany);
