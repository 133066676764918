import {
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_FAIL,
  CHANGE_PASSWORD_SUCCESS,
  RESET_CHANGE_PASSWORD_FLAG,
} from "./actionTypes";

export const changePass = (data, history) => {
  return {
    type: CHANGE_PASSWORD,
    payload: { data, history },
  };
};

export const changePassSuccess = (data) => {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    payload: data,
  };
};

export const changePassFail = (error) => {
  return {
    type: CHANGE_PASSWORD_FAIL,
    payload: error,
  };
};

export const resetChangePassFlag = () => {
  return {
    type: RESET_CHANGE_PASSWORD_FLAG,
  };
};
