export const keyExists = (obj, key) => {
  if (!obj || (typeof obj !== "object" && !Array.isArray(obj))) {
    return false;
  } else if (obj.hasOwnProperty.call(obj, key)) {
    return true;
  } else if (Array.isArray(obj)) {
    for (let i = 0; i < obj.length; i++) {
      const result = keyExists(obj[i], key);
      if (result) {
        return result;
      }
    }
  } else {
    for (const k in obj) {
      const result = keyExists(obj[k], key);
      if (result) {
        return result;
      }
    }
  }

  return false;
};

export function deepCopy(src, keyFind, obj) {
  let target = Array.isArray(src) ? [] : {};
  for (let key in src) {
    let v = src[key];
    if (v) {
      if (typeof v === "object") {
        target[key] = deepCopy(v, keyFind, obj);
      } else {
        if (keyFind === key) {
          target[key] = { v, ...obj };
        } else {
          target[key] = v;
        }
      }
    } else {
      if (keyFind === key) {
        target[key] = { ...v, ...obj };
      } else {
        target[key] = v;
      }
    }
  }

  return target;
}

export function mapObjectToTree(obj) {
  const tree = [];

  function traverse(node, parent) {
    const keys = Object.keys(node);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const value = node[key];

      const newNode = {
        value: `${parent ? parent.value + "." : ""}${key}`,
        title: key,
      };

      if (typeof value === "object" && value !== null) {
        newNode.children = [];
        traverse(value, newNode);
      }

      if (parent) {
        parent.children = parent.children || [];
        parent.children.push(newNode);
      } else {
        tree.push(newNode);
      }
    }
  }

  traverse(obj, null);
  return tree;
}

export const convertNameFolder = (string) => {
  return string.replace("/", "");
};

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export function convertBytesToStorage(bytes) {
  const units = ["PB", "TB", "GB"];
  const sizes = [1024 ** 5, 1024 ** 4, 1024 ** 3];

  for (let i = 0; i < sizes.length; i++) {
    const size = sizes[i];
    if (bytes >= size) {
      const value = bytes / size;
      const roundedValue = Math.round(value * 100) / 100; // Làm tròn đến 2 chữ số thập phân
      return `${roundedValue} ${units[i]}`;
    }
  }

  return `${bytes} bytes`; // Nếu bytes nhỏ hơn 1GB, trả về giá trị gốc theo byte
}

export const handleRenderIcon = (nameFile) => {
  if (nameFile) {
    const arrName = nameFile?.split(".");
    const typeFile = arrName[arrName.length - 1];
    let icon = "";
    switch (String(typeFile.toLowerCase())) {
      case "xlsx":
        icon = "ri-file-excel-fill text-success";
        break;
      case "xls":
        icon = "ri-file-excel-fill text-success";
        break;
      case "csv":
        icon = "ri-file-excel-fill text-success";
        break;
      case "video":
        icon = "mdi mdi-file-video text-secondary";
        break;
      case "mov":
        icon = "mdi mdi-file-video text-secondary";
        break;
      case "zip":
        icon = "bx bxs-file-archive text-warning";
        break;
      case "rar":
        icon = "bx bxs-file-archive text-warning";
        break;
      case "dmg":
        icon = "bx bxs-file-archive text-warning";
        break;
      case "exe":
        icon = "bx bxs-file-archive text-warning";
        break;
      case "wav":
        icon = "ri-file-music-fill text-secondary";
        break;
      case "txt":
        icon = "bx bxs-file-txt text-dark";
        break;
      case "html":
        icon = "ri-file-code-fill text-danger";
        break;
      case "xml":
        icon = "ri-file-code-fill text-danger";
        break;
      case "md":
        icon = "bx bxs-file-md text-dark";
        break;
      case "json":
        icon = "bx bxs-file-json text-dark";
        break;
      case "js":
        icon = "bx bxs-file-js text-dark";
        break;
      case "css":
        icon = "bx bxs-file-css text-dark";
        break;
      case "jpeg":
        icon = "mdi mdi-file-image text-primary";
        break;
      case "jpg":
        icon = "mdi mdi-file-image text-primary";
        break;
      case "png":
        icon = "mdi mdi-file-image text-primary";
        break;
      case "gif":
        icon = "mdi mdi-file-image text-primary";
        break;
      case "doc":
        icon = "ri-file-word-fill text-primary";
        break;
      case "docx":
        icon = "ri-file-word-fill text-primary";
        break;
      case "pdf":
        icon = "bx bxs-file-pdf text-danger";
        break;
      case "mp3":
        icon = "ri-file-music-fill text-secondary";
        break;
      case "mp4":
        icon = "mdi mdi-file-video text-secondary";
        break;
      case "svg":
        icon = "mdi mdi-file-image text-primary";
        break;
      case "pptx":
        icon = "ri-file-ppt-fill text-danger";
        break;
      default:
        icon = "ri-file-info-fill text-danger";
    }
    return icon;
  }
  return "";
};

export const convertNameFie = (nameFile) => {
  const arr = nameFile?.split("/");
  if (arr) {
    return arr[arr.length - 1];
  }

  return nameFile;
};

export function cloneObjectOrArray(item) {
  if (item === null || typeof item !== "object") {
    return item; // Return the item directly if it's not an object or array
  }

  // Create a new object or array
  const clonedItem = Array.isArray(item) ? [] : {};

  // Iterate through the object keys or array elements
  for (const key in item) {
    if (Object.prototype.hasOwnProperty.call(item, key)) {
      // Recursively clone the nested objects or arrays
      clonedItem[key] = cloneObjectOrArray(item[key]);
    }
  }

  return clonedItem;
}

export const convertArrToObject = (arr = [], KEY, VALUE) => {
  if (arr?.length === 0 && !KEY && !VALUE) return {};
  console.log("arr", arr);
  return arr.reduce((obj, item) => {
    console.log(item[KEY], item[VALUE]);
    obj[item[KEY]] = item[VALUE];
    return obj;
  }, {});
};

export const objectToArray = (obj = {}, KEY, VALUE) => {
  if (!KEY && !VALUE) return [];
  if (obj && Object.keys(obj).length > 0) {
    return Object.keys(obj).map((key) => ({ [KEY]: key, [VALUE]: obj[key] }));
  }
  return [];
};

export const formatCurrency = (string) => {
  if (!string) return "";
  const value = string.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return value;
};
