import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Alert, Button, Form, FormFeedback, Input, Label } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { withTranslation } from "react-i18next";

import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";

import { changePass, resetChangePassFlag } from "../../../../store/auth/changepwd/actions";

const ChangePassword = ({ t }) => {
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("authUser"));

  const [oldPasswordShow, setOldPasswordShow] = useState(false);
  const [newPasswordShow, setNewPasswordShow] = useState(false);
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      password: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required(t("Please Enter Your Password")),
      newPassword: Yup.string()
        .min(8, t("Password must be at least 8 characters"))
        .matches(RegExp("(.*[a-z].*)"), t("At least lowercase letter"))
        .matches(RegExp("(.*[A-Z].*)"), t("At least uppercase letter"))
        .matches(RegExp("(.*[0-9].*)"), t("At least one number"))
        .matches(/[/\W|_/g]/, "Password requires a symbol")
        .required(t("Please Enter Your New Password")),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], t("Passwords must match"))
        .required(t("Confirm password is required")),
    }),
    onSubmit: (values) => {
      const payload = { username: user.Username, password: values.password, newPassword: values.newPassword };
      dispatch(changePass(payload));
    },
  });

  const { error, loading, changePassMsg } = useMemoizedSelector((state) => ({
    changePassMsg: state.ChangePassword.changePassMsg,
    error: state.ChangePassword.error,
    loading: state.ChangePassword.loading,
  }));

  useEffect(() => {
    if (changePassMsg === "Password is update successfully!") {
      validation.resetForm();
    }
    if (!loading && !!error) {
      let resetMsgTime = setTimeout(() => {
        dispatch(resetChangePassFlag());
      }, 3000);
      return () => clearTimeout(resetMsgTime);
    }
  }, [dispatch, error, loading, changePassMsg]);

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      {error && error ? (
        <Alert color="danger">
          <div>{t(error)}</div>
        </Alert>
      ) : null}
      {changePassMsg && changePassMsg ? (
        <Alert color="success">
          <div>{t(changePassMsg)}</div>
        </Alert>
      ) : null}
      <div className="row g-2">
        <div className="col-lg-4">
          <Label htmlFor="password" className="form-label">
            {t("Old Password")}*
          </Label>
          <div className="position-relative auth-pass-inputgroup mb-3">
            <Input
              name="password"
              className="form-control"
              placeholder={t("Enter old password")}
              type={oldPasswordShow ? "text" : "password"}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.password || ""}
              invalid={validation.touched.password && validation.errors.password ? true : false}
            />
            {validation.touched.password && validation.errors.password ? (
              <FormFeedback type="invalid">
                <div>{validation.errors.password}</div>
              </FormFeedback>
            ) : null}
            <Button
              color="link"
              onClick={() => setOldPasswordShow(!oldPasswordShow)}
              className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
            >
              <i className="ri-eye-fill align-middle"></i>
            </Button>
          </div>
        </div>
        <div className="col-lg-4">
          <Label htmlFor="new-password" className="form-label">
            {t("New Password")}*
          </Label>
          <div className="position-relative auth-pass-inputgroup mb-3">
            <Input
              name="newPassword"
              className="form-control"
              placeholder={t("Enter new password")}
              type={newPasswordShow ? "text" : "password"}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.newPassword || ""}
              invalid={validation.touched.newPassword && validation.errors.newPassword ? true : false}
            />
            {validation.touched.newPassword && validation.errors.newPassword ? (
              <FormFeedback type="invalid">
                <div>{validation.errors.newPassword}</div>
              </FormFeedback>
            ) : null}
            <Button
              color="link"
              onClick={() => setNewPasswordShow(!newPasswordShow)}
              className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
            >
              <i className="ri-eye-fill align-middle"></i>
            </Button>
          </div>
        </div>
        <div className="col-lg-4">
          <Label htmlFor="confirm-password" className="form-label">
            {t("Confirm Password")}*
          </Label>
          <div className="position-relative auth-pass-inputgroup mb-3">
            <Input
              name="confirmPassword"
              className="form-control"
              placeholder={t("Confirm password")}
              type={confirmPasswordShow ? "text" : "password"}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.confirmPassword || ""}
              invalid={validation.touched.confirmPassword && validation.errors.confirmPassword ? true : false}
            />
            {validation.touched.confirmPassword && validation.errors.confirmPassword ? (
              <FormFeedback type="invalid">
                <div>{validation.errors.confirmPassword}</div>
              </FormFeedback>
            ) : null}
            <Button
              color="link"
              onClick={() => setConfirmPasswordShow(!confirmPasswordShow)}
              className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
            >
              <i className="ri-eye-fill align-middle"></i>
            </Button>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="mb-3">
            <Link to="/forgot-password" className="link-warning text-decoration-underline">
              {t("Forgot password?")}
            </Link>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="text-end">
            <ButtonTheme type="submit" className="btn btn-warning" text="Change Password" loading={loading} />
          </div>
        </div>
      </div>
    </Form>
  );
};

export default withTranslation()(ChangePassword);
