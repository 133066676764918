import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";

import { getInfoAPI, signInAPI } from "../../../helpers/AuthAPI";
import { setAuthorization } from "../../../helpers/api_helper";
import { companiesApi } from "../../../helpers/resource_helper";

function* loginUser({ payload: { user, history, remember } }) {
  try {
    console.log("SDfsdfsd");
    const tokenResponse = yield call(signInAPI, user);

    localStorage.setItem(
      "authUser",
      JSON.stringify({
        Token: tokenResponse,
      }),
    );
    if (tokenResponse) {
      if (tokenResponse?.ChallengeName === "NEW_PASSWORD_REQUIRED") {
        history("/change-password", { state: { username: user.username } });
      } else {
        const { AccessToken, RefreshToken, IdToken } = tokenResponse;
        setAuthorization(IdToken);
        const userInfoResponse = yield call(getInfoAPI);
        if (userInfoResponse) {
          let userAttributes = {};
          userInfoResponse.UserAttributes.forEach((item) => (userAttributes[item.Name] = item.Value));
          const authUser = {
            ...userInfoResponse,
            UserAttributes: userAttributes,
            Token: tokenResponse,
          };
          const isSuperAdmin = authUser?.UserAttributes?.["custom:role"] === "Admin" ? true : false;
          yield put(loginSuccess(authUser));
          localStorage.setItem("authUser", JSON.stringify(authUser));
          if (isSuperAdmin && authUser?.UserAttributes?.["custom:company_id"]) {
            yield call(companiesApi.get, { id: authUser?.UserAttributes?.["custom:company_id"] });
          }
          history("/dashboard");
        } else {
          yield put(apiError(userInfoResponse));
        }
      }
    } else {
      yield put(apiError(tokenResponse));
    }
  } catch (error) {
    const errorString = String(error);
    if (errorString.includes("User is not confirmed")) {
      yield put(apiError(errorString));
      history("/verify-confirmation-code", { state: { email: user.email } });
    } else if (errorString.includes(":")) {
      yield put(apiError(errorString.split(":")?.at(1).trim()));
    } else {
      yield put(apiError(errorString));
    }
  }
}

function* logoutUser() {
  try {
    localStorage.removeItem("authUser");
    yield put(logoutUserSuccess(true));
  } catch (error) {
    yield put(apiError(LOGOUT_USER, error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
