import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback } from "reactstrap";
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import ButtonTheme from "../../Components/Common/ButtonTheme";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { useProfile } from "../../Components/Hooks/UserHooks";
import onexapisLogo from "../../assets/images/onexapis-logo.png";
import useMemoizedSelector from "../../Components/Hooks/useMemoizedSelector";

// action
import { registerUser, apiError, resetRegisterFlag } from "../../store/actions";

const Register = ({ t }) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { userProfile } = useProfile();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      username: "",
      password: "",
      confirm_password: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email(t("Please enter a valid email address")).required("Please enter your email"),
      username: Yup.string().required(t("Please enter your username")),
      password: Yup.string()
        .min(8, t("Password must be at least 8 characters"))
        .matches(RegExp("(.*[a-z].*)"), t("At least lowercase letter"))
        .matches(RegExp("(.*[A-Z].*)"), t("At least uppercase letter"))
        .matches(RegExp("(.*[0-9].*)"), t("At least one number"))
        .matches(/[/\W|_/g]/, "Password requires a symbol")
        .required(t("Password is required")),
      confirm_password: Yup.string()
        .oneOf([Yup.ref("password"), null], t("Passwords must match"))
        .required(t("Confirm password is required")),
    }),
    onSubmit: (values) => {
      const payload = { email: values.email, username: values.username, password: values.password };
      dispatch(registerUser(payload));
    },
  });

  const { error, registrationError, success, loading } = useMemoizedSelector((state) => ({
    registrationError: state.Account.registrationError,
    success: state.Account.success,
    error: state.Account.error,
    loading: state.Account.loading,
  }));

  useEffect(() => {
    dispatch(apiError(""));
  }, [dispatch]);

  useEffect(() => {
    if (success) {
      dispatch(resetRegisterFlag());
      setTimeout(() => history("/verify-confirmation-code", { state: { email: validation.values.email } }), 3000);
    }
    if (!loading && error) {
      let resetMsgTime = setTimeout(() => {
        dispatch(resetRegisterFlag());
      }, 3000);
      return () => clearTimeout(resetMsgTime);
    }
  }, [dispatch, success, error, history, loading]);

  document.title = `${t("Basic Sign Up")} | OneXAPIs`;

  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <Link to="/" className="d-inline-block auth-logo">
                      <img src={onexapisLogo} alt="" height="100" />
                    </Link>
                  </div>
                  <p className="mt-3 fs-15 fw-medium">All-in-One Automation and Integration Solution</p>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-warning">{t("Create New Account")}</h5>
                      <p className="text-muted">{t("Get your free OneXAPIs account now")}</p>
                    </div>
                    <div className="p-2 mt-4">
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                        className="needs-validation"
                        action="#"
                      >
                        {success && success ? (
                          <>
                            {toast("Your Redirect To Login Page...", {
                              position: "top-right",
                              hideProgressBar: false,
                              className: "bg-success text-white",
                              progress: undefined,
                              toastId: "",
                            })}
                            <ToastContainer autoClose={2000} limit={1} />
                            <Alert color="success">
                              {t("Register User Successfully and Your Redirect To Confirmation Page")}...
                            </Alert>
                          </>
                        ) : null}

                        {error && error ? (
                          <Alert color="danger">
                            <div>{t(registrationError)}</div>
                          </Alert>
                        ) : null}

                        <div className="mb-3">
                          <Label htmlFor="useremail" className="form-label">
                            {t("Email")} <span className="text-danger">*</span>
                          </Label>
                          <Input
                            id="email"
                            name="email"
                            className="form-control"
                            placeholder={t("Enter email address")}
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={validation.touched.email && validation.errors.email ? true : false}
                          />
                          {validation.touched.email && validation.errors.email ? (
                            <FormFeedback type="invalid">
                              <div>{validation.errors.email}</div>
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="username" className="form-label">
                            {t("Username")} <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="username"
                            type="text"
                            placeholder={t("Enter username")}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.username || ""}
                            invalid={validation.touched.username && validation.errors.username ? true : false}
                          />
                          {validation.touched.username && validation.errors.username ? (
                            <FormFeedback type="invalid">
                              <div>{validation.errors.username}</div>
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label htmlFor="userpassword" className="form-label">
                            {t("Password")} <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="password"
                            type="password"
                            placeholder={t("Enter Password")}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password || ""}
                            invalid={validation.touched.password && validation.errors.password ? true : false}
                          />
                          {validation.touched.password && validation.errors.password ? (
                            <FormFeedback type="invalid">
                              <div>{validation.errors.password}</div>
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-2">
                          <Label htmlFor="confirmPassword" className="form-label">
                            {t("Confirm Password")} <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="confirm_password"
                            type="password"
                            placeholder={t("Confirm Password")}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.confirm_password || ""}
                            invalid={
                              validation.touched.confirm_password && validation.errors.confirm_password ? true : false
                            }
                          />
                          {validation.touched.confirm_password && validation.errors.confirm_password ? (
                            <FormFeedback type="invalid">
                              <div>{validation.errors.confirm_password}</div>
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-4">
                          <p className="mb-0 fs-12 text-muted fst-italic">
                            {t("By registering you agree to the OneXAPIs")}{" "}
                            <Link to="#" className="text-warning text-decoration-underline fst-normal fw-medium">
                              {t("Terms of Use")}
                            </Link>
                          </p>
                        </div>

                        <div className="mt-4">
                          <ButtonTheme
                            color="success"
                            className="btn btn-warning w-100"
                            type="submit"
                            loading={loading}
                          >
                            Sign Up
                          </ButtonTheme>
                        </div>

                        <div className="mt-4 text-center">
                          <div className="signin-other-title">
                            <h5 className="fs-13 mb-4 title text-muted">{t("Create account with")}</h5>
                          </div>

                          <div>
                            <button type="button" className="btn btn-primary btn-icon waves-effect waves-light">
                              <i className="ri-facebook-fill fs-16"></i>
                            </button>{" "}
                            <button type="button" className="btn btn-danger btn-icon waves-effect waves-light">
                              <i className="ri-google-fill fs-16"></i>
                            </button>{" "}
                            <button type="button" className="btn btn-dark btn-icon waves-effect waves-light">
                              <i className="ri-github-fill fs-16"></i>
                            </button>{" "}
                            <button type="button" className="btn btn-info btn-icon waves-effect waves-light">
                              <i className="ri-twitter-fill fs-16"></i>
                            </button>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-4 text-center">
                  <p className="mb-0">
                    {t("Already have an account?")}{" "}
                    <Link to="/login" className="fw-semibold text-warning text-decoration-underline">
                      {" "}
                      {t("Sign in")}{" "}
                    </Link>{" "}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default withTranslation()(Register);
