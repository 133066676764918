import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useProfile } from "../Components/Hooks/UserHooks";

const Navdata = () => {
  const history = useNavigate();
  //state data
  const [isDashboard, setIsDashboard] = useState(false);
  const [isCompany, setIsCompany] = useState(false);
  const [isUser, setIsUser] = useState(false);
  const [isService, setIsService] = useState(false);
  const [isSubscription, setIsSubscription] = useState(false);
  const [isMessage, setIsMessage] = useState(false);
  const [iscurrentState, setIscurrentState] = useState("Dashboard");

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id)) document.getElementById(id).classList.remove("show");
      });
    }
  }

  const { userProfile, loading, token } = useProfile();

  const isLayout = userProfile?.UserAttributes?.["custom:role"] === "Admin" ? true : false;

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Dashboard") {
      setIsDashboard(false);
    }
    if (iscurrentState !== "company") {
      setIsCompany(false);
    }
    if (iscurrentState !== "user") {
      setIsUser(false);
    }
    if (iscurrentState !== "Service") {
      setIsService(false);
    }
    if (iscurrentState !== "Subscription") {
      setIsSubscription(false);
    }
    if (iscurrentState !== "Messages") {
      setIsSubscription(false);
    }
  }, [history, iscurrentState, isDashboard]);
  const menuItems = [
    {
      id: "Dashboard",
      label: "Dashboards",
      icon: "ri-dashboard-2-line",
      link: "/dashboard",
      stateVariables: isDashboard,
      click: function (e) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIscurrentState("Dashboard");
        updateIconSidebar(e);
      },
    },
    {
      id: "user",
      label: "User",
      icon: "ri-contacts-line",
      link: "/#",
      stateVariables: isUser,
      click: function (e) {
        e.preventDefault();
        setIsUser(!isUser);
        setIscurrentState("user");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "user-list-user",
          label: "List user",
          link: "/list-user",
          parentId: "user",
        },
      ],
    },
    {
      id: "company",
      label: "Company",
      icon: "ri-building-line",
      link: "/#",
      stateVariables: isCompany,
      click: function (e) {
        e.preventDefault();
        setIsCompany(!isCompany);
        setIscurrentState("company");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "company-add-company",
          label: "Add company",
          link: "/add-company",
          parentId: "company",
        },
        {
          id: "company-list-company",
          label: "List company",
          link: "/list-company",
          parentId: "company",
        },
      ],
    },
    {
      id: "Service",
      label: "Service",
      icon: "ri-apps-2-line",
      link: "/list-service",
      stateVariables: isService,
      click: function (e) {
        e.preventDefault();
        setIsService(!isService);
        setIscurrentState("Service");
        updateIconSidebar(e);
      },
    },
    {
      id: "Subscription",
      label: "Subscription",
      icon: "ri-shopping-cart-line",
      link: "/list-subscription",
      stateVariables: isSubscription,
      click: function (e) {
        e.preventDefault();
        setIsSubscription(!isSubscription);
        setIscurrentState("Subscription");
        updateIconSidebar(e);
      },
    },
    {
      id: "Messages",
      label: "Messages",
      icon: "ri-message-3-line",
      link: "/list-message",
      stateVariables: isMessage,
      click: function (e) {
        e.preventDefault();
        setIsMessage(!isMessage);
        setIscurrentState("Messages");
        updateIconSidebar(e);
      },
    },
  ];

  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
