import React, { useCallback, useEffect, useImperativeHandle, useState } from "react";
import { useDispatch, useMemoizedSelector } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import ButtonTheme from "../../Components/Common/ButtonTheme";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import InputTheme from "../../Components/Common/InputTheme";
import { getListCompany, getListUser } from "../../store/actions";
import { useProfile } from "../../Components/Hooks/UserHooks";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import { userApi } from "../../helpers/resource_helper";
import SelectTheme from "../../Components/Common/SelectTheme";

function ModalDetailUser({ isEdit, isOpen, toggler, isAdmin, idCompany, listCompany, t, defaultCompany = "" }) {
  const [modalFile, setModalFile] = useState(false);
  const [loading, setLoading] = useState(false);

  const { userProfile, token } = useProfile();
  const dispatch = useDispatch();
  const form = {
    initialValues: {
      company_id: defaultCompany,
      username: "",
      email: "",
      is_admin: null,
      password: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({}),
    onSubmit: async (values, actions) => {
      setLoading(true);
      try {
        if (idCompany && isAdmin) {
          values.company_id = idCompany;
        }
        await userApi.create(values);
        toast.success(t("Add user success!"));
        actions.resetForm();
        toggler();
        if (idCompany && isAdmin) {
          dispatch(getListUser(idCompany));
        }
      } catch (error) {
        toast.error("Error");
      }
      setLoading(false);
    },
  };

  return (
    <Modal id="createFileModal" isOpen={isOpen} modalClassName="zoomIn" centered tabIndex="-1">
      <ModalHeader toggle={toggler} className="p-3 bg-soft-warning">
        {t("Add User")}
      </ModalHeader>
      <ModalBody>
        <>
          <Formik
            enableReinitialize={true}
            initialValues={form.initialValues}
            validationSchema={form.validationSchema}
            onSubmit={form.onSubmit}
          >
            {() => (
              <Form>
                {!isAdmin && (
                  <SelectTheme
                    name="company_id"
                    placeholder={"Select..."}
                    label={"Company"}
                    options={(listCompany || []).map((item) => ({ value: item.id, label: item.name }))}
                  />
                )}
                <InputTheme name="username" label={t("User Name")} />
                <InputTheme name="email" label={t("Email")} />
                {!isAdmin && <InputTheme name="is_admin" label={t("Role Admin")} type="checkbox" />}
                <InputTheme name="password" label={t("Password")} type="password" />

                <div className="text-end">
                  <ButtonTheme type="submit" className="btn btn-warning w-sm" loading={loading}>
                    Create
                  </ButtonTheme>
                  <ButtonTheme
                    type="button"
                    className="btn btn-danger w-sm mx-2"
                    onClick={() => {
                      toggler();
                    }}
                  >
                    Close
                  </ButtonTheme>
                </div>
              </Form>
            )}
          </Formik>
        </>
      </ModalBody>
    </Modal>
  );
}

export default withTranslation()(ModalDetailUser);
